import { useCallback } from 'react';
import { Alert, Typography, Box, CircularProgress, Button } from '@mui/material';
import { useTranslate, useSafeSetState } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { Head2 } from "../theme";
import { BareLayout } from './layout';
import { Settings } from '../Settings';
import authProvider from '../lib/auth_provider';
import { NoAccounts, LiveHelp, Replay } from '@mui/icons-material';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

const VidChainRedirect = ({setReloadLang}) => {
  const [searchParams,] = useSearchParams();
  const translate = useTranslate();
  const vidchainCode = searchParams.get("code");
  const state = searchParams.get("state").replaceAll(" ", "+");
  const clientId = state.split("-")[0];

  return (<BareLayout>
    <Box
      display="flex"
      flexDirection="column"
      marginTop="3em"
      alignItems="center"
      minHeight="50vh"
    >
      { clientId ?
        <RegularLogin vidchainCode={vidchainCode} clientId={clientId} setReloadLang={setReloadLang} /> :
        <ErrorLoginMessage error={ translate("components.vidchain_redirect.invalid_redirect_state") } />
      }
    </Box>
  </BareLayout>);
}

const RegularLogin = ({ vidchainCode, clientId, setReloadLang }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [error, setError] = useSafeSetState();

  const onVerify = useCallback(async (token) => {
    try {
      await authProvider.login(clientId, token, vidchainCode);
      setReloadLang(true);
      navigate("/");
    } catch (e) {
      setError(e.message || translate("ra.auth.auth_check_error"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (!error ?
    <>
      <CircularProgress sx={{mb: 3}}/>
      <Head2>{ translate("components.login.logging_in") } </Head2>
      <GoogleReCaptchaProvider reCaptchaKey={ Settings.recaptchaSiteKey }>
        <GoogleReCaptcha onVerify={onVerify} />
      </GoogleReCaptchaProvider>
    </>
    :
    <>
      <ErrorLoginMessage error={error} />
      <Button
        sx={{mb: 2}}
        variant="contained"
        onClick={() => navigate("/login")}
        startIcon={<Replay />}
      >
        { translate("components.login.try_again") }
      </Button>
      <Button
        variant="outlined"
        href="mailto:werify@werify.eu"
        startIcon={<LiveHelp />}
      >
        { translate("components.login.contact_us") }
      </Button>
    </>
  );
}

const ErrorLoginMessage = ({error}) => {
  const translate = useTranslate();
  const maybeError = error.split(":")[1]?.trim();
  const finalError = translate(`components.login.errors.${maybeError}`, { _: error });
  return <>
      <NoAccounts sx={{ mb: 3, width: "2em", height: "auto" }}/>
      <Head2 sx={{mb: 3}}>{ translate("components.login.error_title") } </Head2>
      <Typography>{ translate("components.login.error_description") }</Typography>
      <Alert severity="info" sx={{my: "2em" }}>{ finalError }</Alert>
  </>;
}

export {VidChainRedirect, ErrorLoginMessage};
