import { useEffect } from 'react';
import { List, Datagrid, ShowButton, EditButton, TextField, TextInput, required, DeleteWithConfirmButton,
         Show, Edit, SimpleForm, TopToolbar, Create, SimpleShowLayout, useSafeSetState, BooleanInput, BooleanField
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";
import { OnlySaveToolbar } from '../components/bottom_toolbars';
import { TopToolbarWithCreateButton } from '../components/top_toolbars';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';


function KnownCredentialTypeList() {
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const knownCredentialTypeFilters = [
    <FilterTextInput source="valueLike" alwaysOn />,
    <FilterTextInput source="helperLike" alwaysOn />,
  ];
  

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={knownCredentialTypeFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={requirePermissions.canWriteAdminResources(permissions) ? <TopToolbarWithCreateButton/> : <TopToolbar />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='value' />
        <TextField source='helper' />
        <BooleanField source="enabledInVidconnect"/>
        <ShowButton />
        {requirePermissions.canWriteAdminResources(permissions) && <EditButton />}
        {requirePermissions.canWriteAdminResources(permissions) && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
}


function KnownCredentialTypeShow(){
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const Toolbar = () => requirePermissions.canWriteAdminResources(permissions) ? <TopToolbar><EditButton /><DeleteWithConfirmButton /></TopToolbar> : <TopToolbar />;
  return (
    <Show actions={<Toolbar />} >
      <SimpleShowLayout>
        <TextField source='value' />
        <TextField source='helper' />
        <BooleanField source="enabledInVidconnect"/>
      </SimpleShowLayout>
    </Show>
  );
}


const KnownCredentialTypeCreate = () => {
  return (
    <Create title='resources.KnownCredentialType.create' resource="KnownCredentialType" redirect="show">
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="value" autoComplete="off" validate={required()} />
          <TextInput source="helper" autoComplete="off" validate={required()} />
          <BooleanInput source="enabledInVidconnect"/>
        </SimpleForm>
    </Create>
  );
}



const KnownCredentialTypeEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput source="value" autoComplete="off" validate={required()} />
        <TextInput source="helper" autoComplete="off" validate={required()} />
        <BooleanInput source="enabledInVidconnect"/>
      </SimpleForm>
    </Edit>
  )
};



export {KnownCredentialTypeList, KnownCredentialTypeShow, KnownCredentialTypeCreate, KnownCredentialTypeEdit};
