import {
    TextField, Show, SimpleShowLayout, NumberField, useSafeSetState, useTranslate,
} from 'react-admin';
import { Divider, Box, Card, CardContent } from '@mui/material';
import SelectStatisticFilter from '../components/select_statistics_filter';

const Statistics = () => {
  const translate = useTranslate();
  const [statistics, setStatistics] = useSafeSetState({});

    return (
      <Card>
        <CardContent>
          <SelectStatisticFilter setStatistics={setStatistics} />
          <Divider />
          <SimpleShowLayout record={statistics}>
            <NumberField source="persons" />
            <NumberField source="werifyPoints" />
            <NumberField source="rules"/>
            <NumberField source="approvedAttempts"  />
            <NumberField source="rejectedAttempts" />
            <NumberField source="failedAttempts" />
          </SimpleShowLayout>
        </CardContent>
      </Card>
    );
}

export default Statistics;