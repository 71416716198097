import { useRef } from 'react';
import { Input, Box, Button } from '@mui/material';
import { useTranslate, useNotify } from 'react-admin';
import { Upload } from '@mui/icons-material';
import _ from "lodash";
import Papa from 'papaparse';

function CSVFileInputGeneric({
  id, 
  setLoading, 
  buttonLabel, 
  handleFileProcessing,
  additionalDisabledCondition = false
}) {
  const notify = useNotify();
  const translate = useTranslate();
  const inputRef = useRef(null);

  const handleButtonClick = () => {
    const input = inputRef?.current?.firstChild?.firstChild;
    input && input.click();
  };

  const handleFileChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: async function(results) {
          try {
            await handleFileProcessing(results);
          } catch (e) {
            notify(`Error: ${e}`, { type: 'error' });
          } finally {
            setLoading(false);
          }
        },
        header: true,
        skipEmptyLines: true,
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <Box ref={inputRef} id={id}>
      <Input
        type="file"
        onChange={handleFileChange}
        sx={{ display: 'none' }}
      />
      <Button startIcon={<Upload />} variant="text" size="small" onClick={handleButtonClick} disabled={additionalDisabledCondition}>
        {translate(buttonLabel)}
      </Button>
    </Box>
  );
}

export default CSVFileInputGeneric;