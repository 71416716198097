import { useEffect } from 'react';
import {AppBar, Toolbar, IconButton, Box, Button, Container, styled, Backdrop, useMediaQuery, Avatar, Tooltip, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useLogout, useGetOne, useSafeSetState, useTranslate, useRedirect } from "react-admin";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Logo } from '../assets/svg/logo_werify_white.svg';
import { ReactComponent as CyanLogo } from '../assets/svg/logo_werify_cyan.svg';
import Loading from "./loading";
import WerifyTheme from "../theme";
import { Dashboard, Hub, SupervisorAccount, Help, Logout } from '@mui/icons-material';
import authProvider, { getSessionAndSetIt, getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';


export const ResponsiveAppBar = ({loggedIn, logoUrl, fgColor, bgColor}) => {
  const logout = useLogout();
  const translate = useTranslate();
  const redirect = useRedirect();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useSafeSetState(false);
  const [permissions, setPermissions] = useSafeSetState("");
  const [session, setSession] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);

  useEffect(() => {
    getPermissionsAndSetThem({authProvider, setPermissions, setLoading});
    getSessionAndSetIt({setSession});
  }, []);
  
  if (loading) return;

  bgColor = bgColor || "#001E35";
  fgColor = fgColor || "#00FFFF";

  const MobileMenu = () => <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "end" }} id="mobile-menu">
    <IconButton
      size="large"
      aria-controls="menu-appbar"
      onClick={() => setMenuOpen(true) }
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
    <Backdrop
      sx={{ color: '#fff', backgroundColor:"rgba(0, 0, 0, 0.9)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={menuOpen}
      onClick={() => setMenuOpen(false) }
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" flexDirection="column" sx={{mb: "2em"}}>
          <Avatar sx={{ mx: 1 }}/>
          <Typography>{session?.emailAddress}</Typography>
        </Box>
        <IconButton sx={{ "svg": { fontSize: "80px !important" }, mb: 2 }} color="inverted" onClick={ () => setMenuOpen(false) } >
          <CloseIcon />
        </IconButton>
        <Button size="large"sx={{ fontSize: 40, mb: 2, textTransform: "uppercase"}} color="inverted" onClick={() => redirect("/") } startIcon={<Dashboard />}  id="dashboard-mobile-menu-item">
          { translate("components.menu.dashboard") }
        </Button>
        <Button size="large"sx={{ fontSize: 40, mb: 2, textTransform: "uppercase"}} color="inverted" onClick={() => redirect("/favorites") } startIcon={<Hub />}  id="favorites-mobile-menu-item">
          { translate("components.menu.favorites") }
        </Button>
        { requirePermissions.canReadAdminResources(permissions) &&
          <Button size="large"sx={{ fontSize: 40, mb: 2, textTransform: "uppercase"}} color="inverted" onClick={() => redirect("/Statistic") }  startIcon={<SupervisorAccount />} id="admin-mobile-menu-item">
            { translate("components.menu.admin") }
          </Button>
        }
        <Button size="large"sx={{ fontSize: 40, mb: 2, textTransform: "uppercase"}} color="inverted" startIcon={<Help />} onClick={() => window.open(`${window.location.origin}/#/help`, "_blank") } target="_blank" id="help-mobile-menu-item">
          { translate("components.menu.help") }
        </Button>
        <Button size="large" sx={{ fontSize: 40, mb: 2, textTransform: "uppercase" }} color="inverted" startIcon={<Logout />} onClick={() => logout() } id="logout-mobile-menu-item">
          { translate("components.menu.logout") }
        </Button>
      </Box>
    </Backdrop>
  </Box>

  const ComputerMenu = () => <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:"end" }} id="desktop-menu">
    <Button sx={{ ml: 1, textTransform: "uppercase", color: fgColor }} onClick={() => redirect("/") } startIcon={<Dashboard />}  id="dashboard-menu-item">
      { translate("components.menu.dashboard") }
    </Button>
    <Button sx={{ ml: 1, textTransform: "uppercase", color: fgColor }} onClick={() => redirect("/favorites") } startIcon={<Hub />}  id="favorites-menu-item">
      { translate("components.menu.favorites") }
    </Button>
    { requirePermissions.canReadAdminResources(permissions) &&
      <Button sx={{ ml: 1, textTransform: "uppercase", color: fgColor }} onClick={() => redirect("/Statistic") } startIcon={<SupervisorAccount />} id="admin-menu-item">
        { translate("components.menu.admin") }
      </Button>
    }
    <Button sx={{ ml: 1, textTransform: "uppercase", color: fgColor }} startIcon={<Help />} onClick={() => window.open(`${window.location.origin}/#/help`, "_blank") } target="_blank" id="help-menu-item">
      { translate("components.menu.help") }
    </Button>
    <AvatarComponent emailAddress={session?.emailAddress} />
    <Button sx={{ ml: 1, textTransform: "uppercase", color: fgColor }} variant="outlined" onClick={() => logout() } id="logout-menu-item">
      { translate("components.menu.logout") }
    </Button>
  </Box>

  return (
    <AppBar position="static" sx={{ py: isSmall ? "10px" : "20px", background: bgColor, color: fgColor}}>
      <Container maxWidth="md" style={{ padding: 0}}>
        <Toolbar sx={{ minHeight: "0 !important" }}>
          <Box sx={{ display: "flex"}} >
            { logoUrl ?
              <img src={logoUrl} alt={translate("components.menu.logo")} style={{ height: isSmall ? "30px" : "50px", width: "auto" }}/>
              :
              logoUrl !== null && <Logo style={{ height: isSmall ? "30px" : "50px", width: "auto" }} />
            }
          </Box>
          {loggedIn ? <>
              <MobileMenu />
              <ComputerMenu />
            </>
            :
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent:"end" }} id="logout-menu">
              <Button sx={{ ml: 1, textTransform: "uppercase", color: fgColor }} startIcon={<Help />} onClick={() => window.open(`${window.location.origin}/#/help`, "_blank") } target="_blank" id="help-menu-item">
                { translate("components.menu.help") }
              </Button>
            </Box>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
}


const AvatarComponent = ({emailAddress}) => {
  const [open, setOpen] = useSafeSetState(false);
  const handleOpen = (e) => {
    e.target.focus();
    setOpen(true)
  };
  const handleClose = (e) => {
    e.target.blur();
    setOpen(false)
  };

  return (
    <Tooltip
        title={emailAddress}
        placement="bottom"
        leaveDelay={100}
        open={open}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        arrow
      >
          <Avatar sx={{ mx: 1, zIndex: 10000 }}/>
      </Tooltip>
  )
}

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  minHeight: "100vh",
  bgColor: theme.palette.background.default,
  position: "relative",
}));

const AppFrame = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  alignItems: "center",
  height: "100vh",
}));

const Content = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  //overflowX: "auto",
  paddingTop: "2em",
  flex: 2,
}));

export const BareLayout = ({children}) => {
  return (<Box sx={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    }}>
      <CssBaseline/>
      <Container maxWidth="sm">
        { children }
        <Box textAlign="center" mt={8} mb={4}>
          <CyanLogo style={{width: "200px"}} />
        </Box>
      </Container>
    </Box>
  )
}

export const ToolbarLayout = ({children, loggedIn, logoUrl, fgColor, bgColor, footerHtml}) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Root>
      <CssBaseline/>
      <AppFrame>
        <ResponsiveAppBar
          loggedIn={loggedIn}
          logoUrl={logoUrl}
          fgColor={fgColor}
          bgColor={bgColor}
          footerHtml={footerHtml}
        />
        <Content>
          {children}
        </Content>
        <Box sx={{ display: 'flex', width: "100%", py: isSmall ? "14px" : "28px", background: bgColor, color: fgColor}}>
          <Container maxWidth="md" style={{ padding: 0}}>
            { footerHtml && <div dangerouslySetInnerHTML={{__html: footerHtml}} /> }
          </Container>
        </Box>
      </AppFrame>
    </Root>
  )
}

export const NoLoggedInLayout = ({ children }) => {
  return <ToolbarLayout loggedIn={false} children={children} />;
};

export const WerifyLayout = ({ children }) => {
  const {isLoading, data: accountState} = useGetOne( 'AccountState', { id: 1 });
  const {logoUrl, foregroundColor, backgroundColor, footerHtml} = accountState || {};

  if (isLoading) { return <Loading/>; }

  return <ToolbarLayout
    loggedIn={true}
    logoUrl={ logoUrl }
    fgColor={ foregroundColor }
    bgColor={ backgroundColor }
    footerHtml={ footerHtml }
    children={children}
  />;
};

