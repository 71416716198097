import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useTranslate} from 'react-admin';
import _ from 'lodash';

const filter = createFilterOptions();

export default function WerifyAutocomplete({id, initialValue, choices, label, onChange, helperText, errorText, className}) {
  const translate = useTranslate();
  const stringToValue = (s) => ({ value: s, label: s })
  const initialAsObject = _.find(choices, (v) => v.value == initialValue) || stringToValue(initialValue)
  const [value, setValue] = React.useState(initialAsObject);

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      setValue(event.target.value);
    }
  };
  
  const onUserInput = (event, selection) => {
    if(_.isObject(selection)) {
      setValue(selection);
      onChange(selection.value);
    } else {
      setValue(stringToValue(selection));
      onChange(selection)
    }
  }
  
  return (
    <Autocomplete
      value={value}
      onChange={onUserInput}
      onInputChange={onUserInput}
      selectOnFocus
      fullWidth
      handleHomeEndKeys
      className={className}
      id={id}
      options={choices}
      getOptionLabel={(option) => {
        return _.isObject(option) ? (option.label || "") : (option || "");
      }}
      renderOption={(props, option) => <li key={option.id} {...props}>{option.label}</li>}
      freeSolo
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        return <TextField
          {...params}
          label={translate(label)}
          error={!!errorText}
          helperText={translate(errorText || helperText)}
        />;
      }}
    />
  );
}
