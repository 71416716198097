import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { makeKeys, setAuthKeys } from "../lib/auth_provider";
import { accessTokenDataProvider } from "../lib/data_provider";
import { List, Datagrid, Show, SimpleShowLayout, ShowButton, ReferenceField, TopToolbar } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";
import PersonTextField from '../components/person_textfield';


const OneTimeLogin = ({setReloadLang}) => {
  const { access_token } = useParams();

  useEffect(() => {
    async function load(){
      const keys = await makeKeys();      
      const provider = await accessTokenDataProvider(access_token);
      const authString = Buffer.from(keys.sessionPublicKey).toString("base64");

      await provider.create('OneTimeLogin', { data: { input: { authString: authString }}});
      setAuthKeys(keys);
      setReloadLang(true);
      window.location.replace("/#/");
    }

    load().catch(e => { Promise.reject(e) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" id="logging-in">
    <CssBaseline/>
    <CircularProgress sx={{mb: 3}}/>  
  </Box>)
}



function OneTimeLoginList() {

  const oneTimeLoginFilters = [
    <FilterTextInput source="personIdEq" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={oneTimeLoginFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar/>}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
}

function OneTimeLoginShow(){

  return (
    <Show>
      <SimpleShowLayout >
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};



export {OneTimeLogin, OneTimeLoginList, OneTimeLoginShow};
