import { useEffect } from 'react';
import {
  List, Datagrid, ShowButton, TextField, Show, TopToolbar, SimpleShowLayout, ReferenceField,
  DateField, useSafeSetState, BooleanField, FunctionField, Button, useTranslate, useGetOne
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity, DriveFileMove, OpenInNew, List as ListIcon} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import PersonTextField from '../components/person_textfield';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';
import { ArchiveResourceAsUpdate } from '../components/archive_resource_action';
import WrappedField from '../components/wrapped_field';
import { FavWerifyPoint } from '../components/fav_werify_point_action';
import { useParams } from 'react-router-dom';
export const PersonIcon = PermIdentity;


const werifyPointFilters = [
  <FilterTextInput source="nameLike" alwaysOn />,
  <FilterTextInput source="idEq" alwaysOn />,
];

function WerifyPointList() {
  const [permissions, setPermissions] = useSafeSetState("");
  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={werifyPointFilters}
      filter={{archivedAtIsSet: false}}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar/>}
    >
      <Datagrid bulkActionButtons={false}>
        {requirePermissions.canDeleteWerifyPoints(permissions) && <FunctionField render={record => {
          return <FavWerifyPoint action={record.favorite ? "remove_favorite" : "set_favorite"} />
        }}/>
        }
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="ruleId" reference="Rule" link="show">
          <WrappedField source="name" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <BooleanField source="timer" />
        <ShowButton />
        {requirePermissions.canDeleteWerifyPoints(permissions) && <ArchiveResourceAsUpdate resource="WerifyPoint" variant="admin" />}
      </Datagrid>
    </List>
  );
}

function WerifyPointShow(){
  const { id } = useParams();
  const translate = useTranslate();
  const [permissions, setPermissions] = useSafeSetState("");
  const { data: werifyPoint } = useGetOne("WerifyPoint", { id });
  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const Toolbar = () => requirePermissions.canDeleteWerifyPoints(permissions) ?
    <TopToolbar>
      <FavWerifyPoint admin={true} action={werifyPoint.favorite ? "remove_favorite" : "set_favorite"} />
      <ArchiveResourceAsUpdate resource="WerifyPoint" variant="admin" />
    </TopToolbar>
    :
    <TopToolbar />;

  return (
    <Show actions={<Toolbar />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="ruleId" reference="Rule" link="show">
          <WrappedField source="name" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <BooleanField source="timer" />
        <DateField source="createdAt" showTime={true} showDate={true} />
        <ReferenceField source="ruleId" reference="Rule" link="show" label="resources.Rule.fields.redirectUrl" >
          <TextField source="redirectUrl" />
        </ReferenceField>

        <FunctionField render={ record => {
          return <Button
            href={record.fullUrl}
            label="vc_validator.werify_point_list.open_here"
            target="_blank"
          >
            <OpenInNew />
          </Button>
        }} />


        <FunctionField render={ record => {
          return <Button
          href={`#/retrieve-attempts/${record.id}`}
            label="vc_validator.werify_point_list.retrieve_attempts"
          >
            <ListIcon />
          </Button>
        }} />

        <FunctionField render={ record => {
          return <Button
            href={`#/Attempt?displayedFilters={"werifyPointIdEq":true}&filter={"werifyPointIdEq":${record.id}}`}
            label={translate("resources.Attempt.name", {smart_count: 2})}
          >
          <DriveFileMove />
        </Button>
        }} />
      </SimpleShowLayout>
    </Show>
  );
}


export {WerifyPointList, WerifyPointShow, werifyPointFilters};
