import { Box, Typography } from '@mui/material';
import { useTranslate, useRedirect, Button } from 'react-admin';
import { WALLETS } from './select_wallet';


const ClickWallet = ({helpSettings}) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  return (
    <Box display="flex" justifyContent="space-evenly" alignItems="center" sx={{m: "1.5em 0 .5em 0"}}>
      {WALLETS.map(w => {
         const translation = translate(`resources.Attempt.fields.wallets.${w.id}`);
         let kioskUrl;
         if (w.id === "VIDWALLET") {
            kioskUrl = helpSettings.help_kiosk_url_vidchain;
         } else if (w.id === "ALTME") {
          kioskUrl = helpSettings.help_kiosk_url_altme
         } else if (w.id === "TALAO") {
          kioskUrl = helpSettings.help_kiosk_url_talao
         }

         return  <Button key={w.id} id={`select-${w.id}`}  onClick={() => redirect(`/werify_point_kiosk/${kioskUrl}`)} sx={{flexDirection: "row"}}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <img src={w.logo} alt={translation} style={{ width: 75, height: 75 }} />
              <Typography m=".5em">{translation}</Typography>
            </Box>
          </Button>
 
      })}
    </Box>
  );
}

export default ClickWallet;
