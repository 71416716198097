import { CircularProgress, Box } from '@mui/material';

const Loading = () => {
  return(
    <CustomLoading height="100vh" />
  )
}

const CustomLoading = ({height}: {height: string}) => {
  return(
    <Box display="flex" alignItems="center" justifyContent="center" height={height}>
      <CircularProgress/>
    </Box>
  )
}

export default Loading;
export {CustomLoading};
