import { Confirm, useDataProvider, useSafeSetState, useNotify, useGetRecordId, useRefresh, useTranslate, useRedirect } from 'react-admin';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';


const UpdateResourceAction = ({resource, data}) => {
  let { id } = useParams();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [confirmArchiveOpen, setConfirmArchiveOpen] = useSafeSetState(false);

  const handleUpdate = async () => {
    try {
      await dataProvider.update(resource, {id, data});
    } catch (e) {
      notify(`resources.${resource}.errorUpdating`, { type: 'error' });
    }
    setConfirmArchiveOpen(false);
    refresh();
  }

  return <>
    <Button color="error" size="small" onClick={() => setConfirmArchiveOpen(true)} startIcon={<DeleteIcon />}>
      {translate(`resources.${resource}.delete_from_list`)}
    </Button>
    <Confirm
      isOpen={confirmArchiveOpen}
      title={`resources.${resource}.delete.title`}
      onConfirm={handleUpdate}
      onClose={() => setConfirmArchiveOpen(false)}
      content={`resources.${resource}.delete.content`}
    />
  </>
}

export default UpdateResourceAction;
