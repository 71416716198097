import { useEffect } from 'react';
import { List, Datagrid, ShowButton, EditButton, TextField, TextInput, required, DeleteWithConfirmButton,
         Show, Edit, SimpleForm, TopToolbar, Create, SimpleShowLayout, useSafeSetState
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";
import { OnlySaveToolbar } from '../components/bottom_toolbars';
import { TopToolbarWithCreateButton } from '../components/top_toolbars';
import SelectFilter from '../components/select_filter';
import TranslatedTextField from '../components/translated_textfield';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';


function KnownAttributeList() {
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const knownAttributeFilters = [
    <SelectFilter source="filterLike" alwaysOn={true} />,
    <FilterTextInput source="labelLike" alwaysOn />,
    <FilterTextInput source="pointerLike" alwaysOn />,
    <FilterTextInput source="credentialTypeLike" alwaysOn />,
  ];
  

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={knownAttributeFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={requirePermissions.canWriteAdminResources(permissions) ? <TopToolbarWithCreateButton/> : <TopToolbar />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='label' />
        <TextField source='pointer' />
        <TranslatedTextField source="filter" translation="resources.KnownAttribute.fields.filters" />
        <TextField source='credentialType' />
        <ShowButton />
        {requirePermissions.canWriteAdminResources(permissions) && <EditButton />}
        {requirePermissions.canWriteAdminResources(permissions) && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
}


function KnownAttributeShow(){
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const Toolbar = () => requirePermissions.canWriteAdminResources(permissions) ? <TopToolbar><EditButton /><DeleteWithConfirmButton /></TopToolbar> : <TopToolbar />;

  return (
    <Show actions={<Toolbar />} >
      <SimpleShowLayout>
        <TextField source='label' />
        <TextField source='pointer' />
        <TranslatedTextField source="filter" translation="resources.KnownAttribute.fields.filters" />
        <TextField source='credentialType' />
      </SimpleShowLayout>
    </Show>
  );
}


const KnownAttributeCreate = () => {
  return (
    <Create title='resources.KnownAttribute.create' resource="KnownAttribute" redirect="show">
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="label" autoComplete="off" validate={required()} />
          <TextInput source="pointer" autoComplete="off" validate={required()} />
          <SelectFilter source="filter" validate={required()} />
          <TextInput source="credentialType" />
        </SimpleForm>
    </Create>
  );
}


const KnownAttributeEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput source="label" autoComplete="off" validate={required()} />
        <TextInput source="pointer" autoComplete="off" validate={required()} />
        <SelectFilter source="filter" validate={required()} />
        <TextInput source="credentialType" />
      </SimpleForm>
    </Edit>
  )
};


export {KnownAttributeList, KnownAttributeShow, KnownAttributeCreate, KnownAttributeEdit};
