import { Container } from '@mui/material';
import { WerifyLayout } from './layout';
import { NotFound } from 'react-admin';


const NoPermissionsLayout = () => {

  return <WerifyLayout>
    <NoPermissions />
  </WerifyLayout>
}

const NoPermissions = () => {
  return <Container maxWidth="md" id="not-found">
    <NotFound />
  </Container>
}

export {NoPermissions, NoPermissionsLayout};