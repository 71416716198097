const development = {
  apiHost: "http://localhost:8000",
  recaptchaSiteKey: "6LcpP-cpAAAAAFiqbZfI8HTbqKQvj68md3cFPtKc",
  environment: 'development',
  vidconnect: {
    domain: "https://staging.vidchain.net",
    redirectUriLogin: "https://8lh8dmll-8000.brs.devtunnels.ms/vid_chain/login_redirect_uri",
    redirectUriVerifier: "https://8lh8dmll-8000.brs.devtunnels.ms/vid_chain/verifier_redirect_uri",
  },
  altmeRedirectUri: "https://8lh8dmll-8000.brs.devtunnels.ms",
}

const all = {
  "http://localhost:8000": development,
  "http://localhost:3000": development,
  "https://8lh8dmll-3000.brs.devtunnels.ms": development,
  "https://8lh8dmll-8000.brs.devtunnels.ms": development,
  "https://staging.werify.eu": {
    apiHost: "https://staging.werify.eu",
    recaptchaSiteKey: "6LcpP-cpAAAAAFiqbZfI8HTbqKQvj68md3cFPtKc",
    environment: 'staging',
    vidconnect: {
      domain: "https://staging.vidchain.net",
      redirectUriLogin: "https://staging.werify.eu/vid_chain/login_redirect_uri",
      redirectUriVerifier: "https://staging.werify.eu/vid_chain/verifier_redirect_uri",
    },
    altmeRedirectUri: "https://staging.werify.eu",
  },
  "https://points.werify.eu": {
    apiHost: "https://points.werify.eu",
    recaptchaSiteKey: "6LcpP-cpAAAAAFiqbZfI8HTbqKQvj68md3cFPtKc",
    environment: 'production',
    vidconnect: {
      domain: "https://api.vidchain.net",
      redirectUriLogin: "https://points.werify.eu/vid_chain/login_redirect_uri",
      redirectUriVerifier: "https://points.werify.eu/vid_chain/verifier_redirect_uri",
    },
    altmeRedirectUri: "https://points.werify.eu",
  }
}

export const Settings = all[window.origin];
