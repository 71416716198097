import { Confirm, useDataProvider, useSafeSetState, useNotify, useGetRecordId, useRefresh, useTranslate, useRedirect } from 'react-admin';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ArchiveResource = ({resource, variant, archiveFunction}) => {
  const resourceId = useGetRecordId();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [confirmArchiveOpen, setConfirmArchiveOpen] = useSafeSetState(false);
  const buttonId = `delete-${resource}-${resourceId}`;

  const handleArchive = async () => {
    try {
      await archiveFunction(resource, resourceId);
    } catch (e) {
      notify(`vc_validator.archive_resource.${resource}.errorUpdating`, { type: 'error' });
    }
    setConfirmArchiveOpen(false);
    variant === "admin" && redirect(`/${resource}`);
    refresh();
  }

  return <>
    {variant === "admin" ?
      <Button id={buttonId} color="error" size="small" onClick={() => setConfirmArchiveOpen(true)} startIcon={<DeleteIcon />}>{translate("ra.action.delete")}</Button>
    :
      <IconButton id={buttonId} color="error" size="medium" onClick={() => setConfirmArchiveOpen(true) } > <DeleteIcon /> </IconButton>
    }
    <Confirm
      isOpen={confirmArchiveOpen}
      title={`vc_validator.archive_resource.${resource}.title`}
      onConfirm={handleArchive}
      onClose={() => setConfirmArchiveOpen(false)}
      content={`vc_validator.archive_resource.${resource}.content`}
    />
  </>
}

const ArchiveResourceAsUpdate = ({resource, variant}) => {
  const dataProvider = useDataProvider();

  const archiveFunction = async (resource, resourceId) => {
    await dataProvider.update(resource, {
      id: resourceId, data: {input: {id: resourceId, action: "archive"}}, previousData: {} 
    });
  }

  return <ArchiveResource resource={resource} variant={variant} archiveFunction={archiveFunction} />
}

const ArchiveResourceAsDelete = ({resource, variant}) => {
  const dataProvider = useDataProvider();
  
  const archiveFunction = async (resource, resourceId) => {
    await dataProvider.delete(resource, {id: resourceId });
  }

  return <ArchiveResource resource={resource} variant={variant} archiveFunction={archiveFunction} />
}

export {ArchiveResourceAsUpdate, ArchiveResourceAsDelete};
