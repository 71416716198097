import { FunctionField, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

interface TranslatedTextFieldInterface {
  source: string,
  label?: string,
  translation: string,
  sortable?: boolean,
  color?: string,
  fontWeight?: string
}

const TranslatedTextField = ({source, label, translation, sortable, color, fontWeight}: TranslatedTextFieldInterface) => {
  const translate = useTranslate();
  return <FunctionField source={source} label={label} sortable={sortable}
    render={record => { 
      if (!record[source]) return;
      return <Typography color={color} fontWeight={fontWeight} >
        {translate(`${translation}.${record[source]}`)}
      </Typography>
    }}
  />
};

export default TranslatedTextField;