import { useEffect } from 'react';
import { Alert, Box, CircularProgress } from '@mui/material';
import { useTranslate, useSafeSetState, useRedirect } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { accessTokenDataProvider } from '../lib/data_provider';
import { LayoutAccessToken } from './layout_access_token';
import HelpButton from '../components/help_button';
import TryAgainButton from '../components/try_again_button';


const VidChainRedirectVerifier = ({setReloadLang}) => {
  const [dataProvider, setDataProvider] = useSafeSetState(null);
  const [attempt, setAttempt] = useSafeSetState();
  const [error, setError] = useSafeSetState();
  const [searchParams,] = useSearchParams();
  const redirect = useRedirect();
  const vidchainCode = searchParams.get("code");
  const [attemptId, stateCode, accessToken, maybeUserToken] = searchParams.get("state").split("-");
  const access_token = accessToken.replaceAll(" ", "+");

  useEffect(() => {
    const init = async () => {
      const provider = await accessTokenDataProvider(access_token);
      setDataProvider(provider);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getAttempt(){
      try {
        const {data} = await dataProvider.getOne('KioskAttempt', { id: attemptId });
        setAttempt(data);
      } catch {
        setError("vc_validator.kiosk.error_getting_attempt");
      }
    }
    dataProvider && getAttempt();
  }, [dataProvider]);


  useEffect(() => {
    async function updateAttempt(){
      try {
        const {data: updatedAttempt} = await dataProvider.update('KioskAttempt', { id: attemptId, data: { input: { vidchainCode, stateCode: parseInt(stateCode) } }});
        redirect(updatedAttempt.responseCodeRedirect);
      } catch {
        setError("vc_validator.kiosk.error_finishing_attempt");
      }
    }
    dataProvider && updateAttempt();
  }, [dataProvider]);

  return <LayoutAccessToken id="verifier-redirect" resource={attempt} flexDirection="row">
    {!error ?
      <CircularProgress />
      :
      <ErrorComponent access_token={access_token} maybeUserToken={maybeUserToken} error={error} />
    }
  </LayoutAccessToken>;
}

const ErrorComponent = ({ access_token, maybeUserToken, error }) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const redirect = () => {
    if (maybeUserToken) {
      navigate(`/werify_point_kiosk/${access_token}/${maybeUserToken}`)
    } else {
      navigate(`/werify_point_kiosk/${access_token}`)
    }
  }

  return (<Box sx={{maxWidth: "30em", minWidth: "20em", width: "100%"}}>
      <Alert severity="error" sx={{mb: 2}}>{translate(error)}</Alert>
      <TryAgainButton sx={{mb: 2}} onClick={() => redirect() } />
      <HelpButton />
    </Box>);
}


export {VidChainRedirectVerifier};
