import { TextInput, Edit, SimpleForm } from 'react-admin';
import { OnlySaveToolbar } from '../components/bottom_toolbars';


const AccountStateEdit = () => {
  return (
    <Edit redirect="/Person">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput source="logoUrl" autoComplete="off" />
        <TextInput source="foregroundColor" autoComplete="off" />
        <TextInput source="backgroundColor" autoComplete="off" />
        <TextInput source="footerHtml" fullWidth multiline minRows={5} autoComplete="off" />
      </SimpleForm>
    </Edit>
  )
};


export {AccountStateEdit};