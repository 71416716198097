import { useEffect } from "react";
import { Select, Box, FormControl, InputLabel, MenuItem, Checkbox, FormControlLabel, Button } from '@mui/material';
import { useDataProvider, useTranslate, useSafeSetState, useNotify } from 'react-admin';
import { downloadCSV } from "./utils";


const MONTHS = [
  "January", "February", "March", 
  "April", "May", "June",
  "July", "August", "September",
  "October", "November", "December"
];

const SelectStatisticFilter = ({setStatistics}) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [year, setYear] = useSafeSetState(new Date().getFullYear());
  const [month, setMonth] = useSafeSetState(new Date().getMonth() + 1);
  const [historic, setHistoric] = useSafeSetState(false);
  const [onlyYear, setOnlyYear] = useSafeSetState(false);

  const years = [];
  for (let y = 2023; y <= new Date().getFullYear(); y++) {
    years.push(y);
  }

  useEffect(() => {
    getStatistics();
  }, [year, month, historic, onlyYear]);

  const getStatistics = async () => {
    try {
      let stats;
      if (historic) {
        const result = await dataProvider.getOne('Statistic', { id: 1 });
        stats = result.data;
      } else {
        let finalMonth = onlyYear ? null : month;
        const result = await dataProvider.getList('Statistic', {
          sort: {field: "id", order: "ASC"}, pagination: { page: 1, perPage: 1 }, filter: {year, month: finalMonth, completeDetail: false}
        });
        stats = result.data[0]
      }
      setStatistics(stats);
    } catch(e) {
      notify(e.toString(), { type: 'error' });
    }
  }

  const handleDownloadStatistics = async () => {
    const {data} = await dataProvider.getList('Statistic', {
      sort: {field: "id", order: "ASC"}, pagination: { page: 1, perPage: 100_000 }, filter: {year: 2023, completeDetail: true}
    });

    const transformedData = data.map(item => {
      let date;
      if (item.year && item.month) {
        date = translate(`resources.Statistic.months.${MONTHS[item.month - 1]}`) + " " + item.year;
      } else if (item.year){
        date = item.id;
      } else {
        date = translate(`resources.Statistic.${item.id}`)
      }
  
      const { id, year, month, ...rest } = item;
      return { date, ...rest };
    });

    downloadCSV(transformedData, `statistics`);
  }

  return(<Box display="flex" justifyContent="space-between">
    <Box sx={{ marginX: "1.5em", marginY: "1em", display: "flex", flexDirection: "row", gap: "1em"}}>
      <FormControl>
        <InputLabel id="select-year-label">{translate("resources.Statistic.year")}</InputLabel>
        <Select
          labelId="select-year-label"
          id="select-year"
          value={year}
          onChange={(e) => { setYear(e.target.value as number) }}
          sx={{minWidth: "8em"}}
          disabled={historic}
        >
          {years.map(y => (
            <MenuItem key={y} value={y}>{y}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="select-month-label">{translate("resources.Statistic.month")}</InputLabel>
        <Select
          labelId="select-month-label"
          id="select-month"
          value={month}
          onChange={(e) => { setMonth(e.target.value as number) }}
          sx={{minWidth: "12em"}}
          disabled={historic || onlyYear}
        >
          {MONTHS.map((m, index) => (
              <MenuItem key={m} value={index + 1}>{translate(`resources.Statistic.months.${m}`)}</MenuItem>
          ))}
        </Select>
        </FormControl>
        <FormControl>
        <FormControlLabel
          label={translate("resources.Statistic.historic")}
          control={
            <Checkbox checked={historic} onChange={(e) => { setHistoric(e.target.checked); setOnlyYear(false); }} />
          }
        />
      </FormControl>
      <FormControl>
      <FormControlLabel
          label={translate("resources.Statistic.onlyYear")}
          control={
            <Checkbox checked={onlyYear} onChange={(e) => { setOnlyYear(e.target.checked); setHistoric(false); }} />
          }
        />
      </FormControl>
    </Box>
    <Box display="flex" alignItems="center">
    <Button variant="contained" sx={{marginX: "2em"}} onClick={() => handleDownloadStatistics()}>
      {translate("resources.Statistic.download")}
    </Button>
    </Box>
  </Box>
)};

export default SelectStatisticFilter;