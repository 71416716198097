import { Box } from '@mui/material';
import { useTranslate, Button } from 'react-admin';
import { Head2 } from "../theme";
import WerifyTheme from "../theme";
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';




export default function CardTitle({text, ...props}) {
  const translate = useTranslate();

  const colors = WerifyTheme?.palette?.secondary;
  return(<Box {...props} sx={{ background: colors?.light, p: 1, borderBottom: "2px solid", borderColor: colors?.main }}>
    <Head2>{ translate(text, { _: text }) }</Head2>
  </Box>)
}


export function CardTitleWithHideButton({open, setOpen, storage, text, ...props}) {
  const translate = useTranslate();

  const handleClick = () => {
    setOpen(o => {
      localStorage.setItem(storage, `${!o}`);
      return !o
    });
  }

  const colors = WerifyTheme?.palette?.secondary;
  return(<Box {...props} display="flex" justifyContent="space-between" flexDirection="row" backgroundColor={colors?.light} p={1} borderBottom="2px solid" borderColor={colors?.main}>
    <Head2>{ translate(text, { _: text }) }</Head2>
    <Button
      onClick={handleClick}
      startIcon={open ? <ArrowDropUp/> : <ArrowDropDown />}
      sx={{
        '&:hover': {
          backgroundColor: 'initial', // Mantener el color de fondo inicial
        },
        '&:active': {
          backgroundColor: 'initial', // Mantener el color de fondo inicial
        },
        '&:focus': {
          backgroundColor: 'initial', // Mantener el color de fondo inicial
        },
      }}
    />
  </Box>)
}
