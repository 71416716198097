import { Card, CardContent, Typography, Box } from '@mui/material';
import _ from 'lodash';
import { useMediaQuery } from '@mui/material';

const CredentialCard = ({ credential }) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Card sx={{
        borderRadius: "0.4rem !important",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#f0f0f0",
        background: "#f0f0f0",
        padding: ".5em",
        margin: "0.5em 1em 1em 0",
        minHeight: "15em",
        minWidth: isSmall ? "20em" : "29em",
        overflow: "auto"
    }}>
      <CardContent>
        {credential && Object.entries(credential).map(([key, value] : [string, CredentialValue], index) => (
          <Box key={index} sx={{
            marginBottom: '18px',
          }}>
            <Box  sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Typography
                variant="body2"
                component="span"
                sx={{ fontWeight: 'bold', color: value.error ? 'red' : '#333', flexGrow: 1, pr: isSmall ? "0.5em" : "3em", overflowWrap: 'break-word', maxWidth: isSmall ? "10em" : "400px" }}
              >
                {`${key}:`}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                sx={{ color: value.error ? 'red' : '#333', textAlign: 'right', flexGrow: 3, overflowWrap: 'break-word', maxWidth: '400px' }}
              >
                {value.error && value.inline ? value.error : value.value}
              </Typography>
            
            </Box>
            {value.error && !value.inline &&
              <Box display="flex" marginTop="4px">
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'red', textAlign: isSmall ? 'left' : 'right', flexGrow: 3}}
                >
                {`(${value.error})`}
                </Typography>
              </Box>
            }
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

type CredentialValue = {
  value: string,
  error?: string,
  filter?: string,
  inline?: boolean,
}

export default CredentialCard;