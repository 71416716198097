import { TopToolbar, FilterButton, CreateButton } from 'react-admin'


const TopToolbarWithCreateButton = () => {
  return (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
  </TopToolbar> 
)};

export {TopToolbarWithCreateButton};