import {
  Dialog, DialogTitle, Button, Box, List, ListItem, ListItemText, ListItemButton, ListItemIcon
} from '@mui/material';
import { useSafeSetState, useTranslate } from 'react-admin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import _ from "lodash";
import examplesJson from './examples.json'

const AddExampleButton = ({updateDirectives, setError, currentEditor}) => {
  const [open, setOpen] = useSafeSetState(false);
  const translate = useTranslate();

  const handleListItemClick = (selection, editor) => {
    updateDirectives(examplesJson.examples[selection], editor);
    setError(null);
    setOpen(false);
  };

  return <Box display="inline-block" sx={{ml: 1}}>
    <Button size="small" color="info" variant="contained" onClick={() => setOpen(true)} id="add-example-button">
    {translate("wizard.create_rules.alert_button")}
    </Button>
    <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle> {translate("wizard.create_rules.add_example")} </DialogTitle>
    <List sx={{ pt: 0 }} id="rules-example-list">
      <ListItem dense disableGutters>
      <ListItemButton onClick={() => handleListItemClick(0, currentEditor)} key="custom">
        <ListItemIcon>
        <AddCircleIcon />
        </ListItemIcon>
        <ListItemText
          primary={translate("wizard.create_rules.example_one")}
          secondary={translate("wizard.create_rules.both_editors")}
        />
      </ListItemButton>
      </ListItem>
      <ListItem dense disableGutters>
      <ListItemButton onClick={() => handleListItemClick(1, "advanced")} key="custom">
        <ListItemIcon>
        <AddCircleIcon />
        </ListItemIcon>
        <ListItemText
          primary={translate("wizard.create_rules.example_two")}
          secondary={translate("wizard.create_rules.only_advanced_editor")}
        />
      </ListItemButton>
      </ListItem>
    </List>
    </Dialog>
  </Box>;
}

export default AddExampleButton;