import { useEffect } from 'react';
import { Alert, Card, Typography, Container, Button, Box } from '@mui/material';
import { useDataProvider, useSafeSetState, useTranslate, useRedirect, List } from 'react-admin';
import { WerifyLayout } from '../layout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CardTitle from '../../components/card_title';
import { NoPermissionsLayout } from '../no_permissions';
import authProvider, { getPermissionsAndSetThem } from '../../lib/auth_provider';
import requirePermissions from '../../lib/permissions';
import Loading from '../loading';
import {RuleDataGrid} from '../dashboard';
import { RuleEditor } from './general_editor';
import { ButtonBar } from '../wizard_werify_point';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const RuleWizardCreate = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [step, setStep] = useSafeSetState(0);
  const [rule, setRule] = useSafeSetState({});
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  const handleSubmit = async (values, directives, setError) => {
    setError(null);

    try {
      const result = await dataProvider.create('Rule', { data: { input: {
        name: values.name,
        redirectUrl: values.redirectUrl,
        singleUseCredentials: values.singleUseCredentials,
        directives: JSON.stringify(directives)
      } } });
      let rule = result.data;
      setRule(rule);
      setStep(1);
    } catch(e) {
      setError(e.body.message);
    }
  }

  if (loading) return <Loading />;
  if (!requirePermissions.canCreateRule(permissions)) { return <NoPermissionsLayout /> };

  return <WerifyLayout>
    <Container maxWidth="md">
      <Breadcrumbs>
        <Button startIcon={<DashboardIcon />} color="primary" variant="outlined" onClick={() => redirect("/")} sx={{mx: 0}} id="go-dashboard">
          {translate("components.menu.dashboard")}
        </Button>
        <Typography variant="body">{ translate("wizard.create_rule") }</Typography>
      </Breadcrumbs>
    </Container>
    <Container maxWidth="md" id="rule">
      <Box textAlign="center" width="100%" height="auto" margin="2em auto">
          { step === 0 && <RuleEditor handleSubmit={handleSubmit} action="create" /> }
          { step === 1 && <FinishRule rule={rule} /> }
      </Box>
    </Container>
  </WerifyLayout>
}
  
export default RuleWizardCreate;



const FinishRule = ({rule}) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  return <Box>
    <Alert severity="success" sx={{my: "2em" }}>
      { translate("wizard.rule_created_text") }
    </Alert>
    <Card>
    <CardTitle text="wizard.rule_created_title" />
      <Container>
        <List
          empty={<Box sx={{m: 1}}>{ translate("wizard.no_rules") }</Box>}
          resource="Rule"
          pagination={false}
          filter={{ idEq: rule.id }}
          actions={false}
        >
          <RuleDataGrid />
        </List>
      </Container>
    </Card>
    <ButtonBar justifyContent="flex-end">
    <Button endIcon={<ArrowForwardIcon />} color="primary" variant="outlined" onClick={() => redirect("/")} id="go-dashboard">
      {translate("wizard.go_dashboard")}
    </Button>
    </ButtonBar>
  </Box>
}