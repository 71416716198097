import { Pagination, TopToolbar, CreateButton } from 'react-admin'
import * as React from 'react';
import BootstrapTooltip from './tooltip_blue';

export const defaultSort = { field: 'id', order: 'DESC' };

export const PaginationDefault = props => <Pagination rowsPerPageOptions={[20]} {...props} />;
export const PaginationDashboard = props => <Pagination rowsPerPageOptions={[1]} {...props} />;

export const ListCreateActions = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    e.target.focus();
    setOpen(true)
  };
  const handleClose = (e) => {
    e.target.blur();
    setOpen(false)
  };
  
  return (
    <TopToolbar>
      <BootstrapTooltip
        {...props}
        placement="left"
        leaveDelay={200}
        open={open}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        arrow>
          <CreateButtonRef sx={{ zIndex: 10000}} />
      </BootstrapTooltip>
    </TopToolbar> 
)};


const CreateButtonRef = React.forwardRef((props: any, _) => (
    <CreateButton {...props} />
));

export const ListActionsWithoutCreate = () => {
  return (
    <TopToolbar>
    </TopToolbar> 
)};

export const convertToCSV = (arr) => {
  const header = Object.keys(arr[0]).join(',');
  const values = arr.map(obj => Object.values(obj).join(',')).join('\n');
  return `${header}\n${values}`;
};


export const downloadCSV = (finalContent, name) => {
  // Convert data to CSV
  const csvContent = convertToCSV(finalContent);

  // Create a blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create an object URL and trigger a download
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const parseDate = (date) => {
  return new Date(date).toLocaleString([], {
    timeStyle: "short",
    dateStyle: "medium"
  });
}