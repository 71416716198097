import { useEffect } from 'react';
import {
    Alert, Card, Typography, Container, Button, ToggleButton, ToggleButtonGroup, Box
} from '@mui/material';
import { useSafeSetState, required, TextInput, Form, useTranslate, BooleanInput } from 'react-admin';
import CardTitle from '../../components/card_title';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import _ from "lodash";
import { ButtonBar } from '../wizard_werify_point';
import Advanced from './advanced_editor';
import Basic from './basic_editor';
import AddExampleButton from './example_modal';


type IDirective = {
  acceptable_sets: Array<IAcceptableSets>
}

type IAcceptableSets = {
  required_set:  Array<IRequiredSet>
}

type IRequiredSet = {
  credential_spec: Array<Object>
}

type IRule = {
  id?: number,
  name?: string,
  directives?: string,
  redirectUrl?: string | undefined,
  singleUseCredentials?: boolean,
}

interface EditorArgs {
  handleSubmit: Function,
  action: string,
  rule: IRule,
}
  
const RuleEditor = ({handleSubmit, action, rule = {}}: EditorArgs) => {
  const translate = useTranslate();
  const isUpdateAction = action === "update";
  const [directives, setDirectives] = useSafeSetState<IDirective>(null);
  const [currentEditor, setCurrentEditor] = useSafeSetState(null);
  const [error, setError] = useSafeSetState(null);
  const [advanced, setAdvanced] = useSafeSetState(false);

  useEffect(() => {
    if (isUpdateAction && rule.directives) {
      updateDirectives(JSON.parse(rule.directives), null);
    }
  }, [rule.directives])

  const readyToSubmit = () => !_.isEmpty(directives)

  const cannotUseBasic = directives?.acceptable_sets?.length > 1;

  const updateDirectives = (newDirectives, editor) => {
    setCurrentEditor(editor);
    setAdvanced(editor === "advanced");
    setDirectives(newDirectives);
  }

  const handleSubmitWithDirectives = values => {
    if(!readyToSubmit()) {
      return;
    }
    handleSubmit(values, directives, setError);
  }

  return <Box>
    <Form onSubmit={handleSubmitWithDirectives} noValidate id="rule-wizard-step">
      <Card>
        <CardTitle text={isUpdateAction ? "wizard.edit_rule" : "wizard.create_rule" } />
        <Container sx={{my: 2}}>
          <TextInput
            fullWidth
            source="name"
            label="wizard.name"
            defaultValue={isUpdateAction ? rule.name : null}
            validate={[required()]}
            helperText="wizard.ruleNameHelperText"
          />
          <TextInput
            fullWidth
            source="redirectUrl"
            label="wizard.redirectUrl"
            defaultValue={isUpdateAction ? rule.redirectUrl : null}
            validate={[validateURL]}
            helperText="wizard.redirectUrlHelperText"
          />
          <BooleanInput
            sx={{mb: "2em", ml: ".5em"}}
            fullWidth
            source="singleUseCredentials"
            label="wizard.singleUseCredentials"
            helperText="wizard.singleUseCredentialsHelperText"
            defaultValue={isUpdateAction ? rule.singleUseCredentials : false}
          />
          <ToggleButtonGroup
            sx={{mb: 1}}
            fullWidth
            exclusive
            aria-label="text alignment"
            color={"primary"}
            value={"basic"}
          >
            <ToggleButton
              value="basic"
              onClick={ () => setAdvanced(false) }
              aria-label="left aligned" 
              selected={!advanced && !cannotUseBasic }
              id="basic-editor-button"
              disabled={ cannotUseBasic || !!error }
            >
              <Box alignContent="center" display="flex" flexDirection="column">
                { translate("wizard.basic_editor") }
                { cannotUseBasic &&
                  <Box display="flex" alignItems="center" gap="5px">
                    <ReportProblemIcon color="error" fontSize="1em"/>
                    <Typography textTransform="none" fontSize="0.7em" color="error">
                      {translate("wizard.create_rules.basic_view_not_available")}
                    </Typography>
                  </Box>
                }
              </Box>
            </ToggleButton>
            <ToggleButton
              value="advanced"
              onClick={ () => setAdvanced(true) }
              aria-label="right aligned"
              selected={ advanced || cannotUseBasic }
              disabled={!!error}
              id="advanced-editor-button"
            >
              { translate("wizard.advanced_editor") }
            </ToggleButton>
          </ToggleButtonGroup>
          {advanced || cannotUseBasic ?
            <Advanced {...{currentEditor, directives, updateDirectives, setError}} />
            :
            <Basic {...{currentEditor, directives, updateDirectives, setError}} />
          }
          { error && error !== "basic_editor_error" &&
            <Alert variant="outlined" severity="error" id="advanced-rules-error" sx={{my: 2}}>
              { translate(`advanced_rules_error.${error}`) }
            </Alert>
          }
        </Container>
      </Card>
      {!isUpdateAction &&
        <Alert variant="outlined" severity="info" id="create-rule-info" sx={{mt: 2}}>
          {translate("wizard.create_rules.alert_info")}
          <AddExampleButton updateDirectives={updateDirectives} setError={setError} currentEditor={currentEditor} />
        </Alert>
      }
      <ButtonBar>
        <Box flexGrow="1"></Box>
        <Button endIcon={<ArrowForwardIcon />} color="inverted" variant="contained" type="submit" id={isUpdateAction ? "update-rule-button" : "create-rule-button"}>
          {translate("wizard.finish")}
        </Button>
      </ButtonBar>
    </Form>
  </Box>
}
  

const validateURL = (value) => {
  // An empty field is valid.
  if (!value) {
    return undefined;
  }

  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '(localhost|'+ // allow 'localhost'
    '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+'+ // domain name (part before the last dot)
    '[a-z]{2,}|'+ // the top-level domain (TLD)
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?'+ // port
    '(\\/[-a-z\\d%_.~+]*)*'+ // path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_/]*)?$', 'i'); // fragment locator
  if (!pattern.test(value)) {
      return 'wizard.invalidRedirectUrl';
  }
  return undefined;
};


export {RuleEditor};