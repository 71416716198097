import { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Card, Typography, Container, Box, Button, IconButton, CircularProgress } from '@mui/material';
import {
  Datagrid, DateField, useSafeSetState, TextField, FunctionField, List, useNotify, useTranslate,
  useRecordContext, ReferenceField, useRedirect, WithRecord
} from 'react-admin';
import CardTitle from '../components/card_title';
import { CardTitleWithHideButton } from '../components/card_title';
import { Head1 } from '../theme';
import QRCode from "react-qr-code";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { ArchiveResourceAsUpdate } from "../components/archive_resource_action";
import { ReactComponent as WerifyLogoSvg } from '../assets/svg/w_werify_cyan.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as WerifyRejected } from '../assets/svg/x_werify_red.svg';
import { ReactComponent as WerifyApproved } from '../assets/svg/w_werify_green.svg';
import { PaginationDashboard } from '../components/utils';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import { NoPermissions } from './no_permissions';
import requirePermissions from '../lib/permissions';
import Loading from './loading';
import { werifyPointFilters } from './werify_point';
import { ruleFilters } from './rule';
import { FavWerifyPoint } from '../components/fav_werify_point_action';


const Dashboard = () => {
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);
  const translate = useTranslate();
  const redirect = useRedirect();

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  if (loading) return <Loading />;
  if (permissions === "REVOKED") return <NoPermissions />

  return (<Container maxWidth="md" sx={{ pb: "2em" }} id="vc-werify-point-dashboard">
    <Box mb={3} sx={{display: { xs: 'none', md: 'flex' }}} flexWrap="wrap" alignItems="center" gap="2em">
      <Box flexGrow="2" minWidth="70px" flexBasis="100px" maxHeight="min(20vw, 130px)">
        <WerifyLogoSvg />
      </Box>
      <Box flexGrow="10" flexShrink="2" maxWidth="650px" minWidth="250px" flexBasis="250px">
        <Head1 sx={{ mb:2 }}>{ translate("vc_validator.dashboard.title") }</Head1>
        <Typography marginBottom="1em">{ translate("vc_validator.dashboard.subtitle") }</Typography>
      </Box>
    </Box>

    <Box mb={3} sx={{display: { xs: 'flex', md: 'none' }}} flexWrap="wrap" alignItems="center" gap="2em">
      <Box flexGrow="10" flexShrink="2" maxWidth="650px" minWidth="250px" flexBasis="250px">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box minWidth="20px" flexBasis="50px" marginRight="0.5em">
            <WerifyLogoSvg />
          </Box>
          <Head1 sx={{ mb:2 }}>{ translate("vc_validator.dashboard.title") }</Head1>
        </Box>
        <Typography marginBottom="1em">{ translate("vc_validator.dashboard.subtitle") }</Typography>
      </Box>
    </Box>
    
    {requirePermissions.canCreateWerifyPoint(permissions) &&
      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={() => redirect('/create_werify_point') }
        sx={{ fontSize: 20, mb: 5 }}
        id="create-verification-point"
      >
        { translate("wizard.create_werify_point") }
      </Button>
    }

    {requirePermissions.canReadBasicResources(permissions) &&
      <Box>
        <WerifyPointList permissions={permissions} />
        <AttemptList permissions={permissions} />
      </Box>
    }

    {requirePermissions.canCreateRule(permissions) &&
      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={() => redirect('/create_rule') }
        sx={{ fontSize: 20 }}
        id="create-rule"
      >
        { translate("wizard.create_rule_button") }
      </Button>
    }

    {requirePermissions.canReadRules(permissions) &&
      <RuleList permissions={permissions} />
    }
  </Container>)
}


const ConfigureWerifyPoint = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const record = useRecordContext();
  const [open, setOpen] = useSafeSetState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = (toCopy) => {
    navigator.clipboard.writeText(toCopy);
    notify("vc_validator.configure.copied_to_clipboard");
  }
  
  return (<Box>
    <Button sx={{whiteSpace:"nowrap"}} size="small" variant="contained" onClick={() => setOpen(true) }>
      { translate("vc_validator.configure.button_label") }
    </Button>
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" gap="1em">
          <Typography> { translate("vc_validator.configure.description") }</Typography>
          <IconButton sx={{ width: "50px", height: "50px" }} aria-label="close" onClick={handleClose} > <CloseIcon /> </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={record.fullUrl}
            viewBox={`0 0 256 256`}
          />
        </Box>
        <Box>
          <TextField variant="body2" value={record.fullUrl}></TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{m: 1 }}
          fullWidth
          startIcon={<LinkIcon />}
          onClick={() => copyToClipboard(record.fullUrl)}
          variant="contained"
          id="copy-full-url"
          >
          {translate("vc_validator.configure.copy_to_clipboard")}
        </Button>
      </DialogActions>
    </Dialog>
  </Box>);
}


function WerifyPointList({permissions}) {
  const translate = useTranslate();
  const isOpen = localStorage.getItem("pointsListOpen");
  const [open, setOpen] = useSafeSetState(true);

  useEffect(() => {
    isOpen !== null && setOpen(isOpen === "true");
  }, [])

  return (
    <Card sx={{mb: "2em"}} id="werify-point-list">
      <CardTitleWithHideButton open={open} setOpen={setOpen} storage="pointsListOpen" text="vc_validator.werify_point_list.title" />
      {open && 
        <List
          empty={<Box sx={{m: 1}}>{ translate("vc_validator.werify_point_list.empty_message") }</Box>}
          resource="WerifyPoint"
          sort= {{ field: 'id', order: 'DESC' }}
          filters={werifyPointFilters}
          filter={{archivedAtIsSet: false}}
          pagination={<PaginationDashboard />}
          actions={false}
          disableSyncWithLocation
        >
          <WerifyPointDatagrid permissions={permissions} />
        </List>
      }
    </Card>
  );
}


const WerifyPointDatagrid = ({permissions}: {permissions?: string}) => {
  const translate = useTranslate();
  return <Datagrid
    bulkActionButtons={false}
    sx={{ '& .column-name': { width: "100%" } }}
    >
    <TextField source="id" sortable={false} />
    <Box source="name" label="resources.WerifyPoint.many" display="flex" alignItems="center" flexWrap="wrap" gap="1em">
      <Box flex="2" minWidth="200px">
        <TextField source="name" sortable={false} />
        <br/>
        <TextField source="ruleName" variant="caption" sortable={false} />
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center" gap="0.5em">
        <WithRecord label="link" render={record =>
          <Button
            size="small"
            href={record.fullUrl}
            target="_blank"
            endIcon={<OpenInNewIcon />}
            variant="contained"
          >
            {translate("vc_validator.werify_point_list.open_here")}
          </Button>
        } />
        <ConfigureWerifyPoint />
        {requirePermissions.canDeleteWerifyPoints(permissions) &&
          <WithRecord render={record =>
            <FavWerifyPoint action={record.favorite ? "remove_favorite" : "set_favorite"} />
          }/>
        }
        {requirePermissions.canDeleteWerifyPoints(permissions) &&
          <ArchiveResourceAsUpdate resource="WerifyPoint" />
        }
      </Box>
    </Box>
  </Datagrid>;
}

function AttemptList({permissions}: {permissions?: string}) {
  const translate = useTranslate();
  const redirect = useRedirect();
  const isOpen = localStorage.getItem("attemptListOpen");
  const [open, setOpen] = useSafeSetState(true);

  useEffect(() => {
    isOpen !== null && setOpen(isOpen === "true");
  }, [])

  return (
    <Card sx={{my: "2em"}} id="attempt-list">
      <CardTitleWithHideButton open={open} setOpen={setOpen} storage="attemptListOpen" text="vc_validator.attempt_list.title" />
      {open && 
        <List
        empty={<Box sx={{m: 1}}>{ translate("vc_validator.attempt_list.empty_message")} </Box>}
        resource="Attempt"
        queryOptions={ {refetchInterval: 2000 }}
        sort = {{ field: 'finishedAt', order: 'DESC' }}
        filter = {{ finishedAtIsSet: true }}
        pagination={<PaginationDashboard />}
        actions={false}
        disableSyncWithLocation
      >
        <Datagrid
          id="recent-attempts"
          bulkActionButtons={false}
          sx={{ '& .column-state': { maxWidth: "4em" }, '& .column-werifyPointId': { width: "100%" }, '& .column-finishedAt': { whiteSpace: 'nowrap' } }}
        >
          <FunctionField sortable={false} source="state" sx={{ display: "flex", justifyContent: "center" }} label={false} render={ record => {
            const dimensions = { height: 30, width: 30 };
            const id = `${record.state}-${record.id}`;
            switch(record.state) {
              case "APPROVED":
                return <Box lineHeight="0" id={id}> <WerifyApproved height="30px" maxwidth="30px" /> </Box>;
              case "REJECTED":
                return <Box lineHeight="0" id={id}> <WerifyRejected height="30px" maxwidth="30px" /> </Box>;
              case "FAILED":
                return <Box lineHeight="0" color="#c60042" id={id}> <ReportProblemIcon sx={dimensions} /> </Box>;
            }
          }} />
          <Box minWidth="200px">
            <ReferenceField sortable={false} source="werifyPointId" label="resources.Attempt.fields.werifyPointId" reference="WerifyPoint">
              <TextField source="name" />
            </ReferenceField>
            <br/>

            <TextField source="ruleName" variant="caption" sortable={false} />
          </Box>
          
          <FunctionField sortable={false} source="finishedAt" label="resources.Attempt.fields.finishedAt" render={ record => {
            if(!record.finishedAt){ return "…"; }

            return <DateField source="finishedAt" showTime={true} showDate={true}/> 
          }} />

          {requirePermissions.canReadAdminResources(permissions) &&
            <WithRecord render={record =>
              <Button
                size="small"
                onClick={() => redirect(`/Attempt/${record.id}/show`)}
                variant="contained"
              >
                {translate("vc_validator.attempt_list.button_label")}
              </Button>
            }/>
          }
          <TextField source="id" sortable={false} />
        </Datagrid>
      </List>
      }
    </Card>
  );
}



const ShowRules = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const record = useRecordContext();
  const [open, setOpen] = useSafeSetState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = (toCopy) => {
    navigator.clipboard.writeText(toCopy);
    notify("vc_validator.show_rules.copied_to_clipboard");
  }
  
  return (<Box>
    <Button sx={{whiteSpace:"nowrap"}} fullWidth size="small" variant="contained" onClick={() => setOpen(true)} id={`button-show-rules-${record.id}`}>
      { translate("vc_validator.show_rules.button_label") }
    </Button>
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth >
      <DialogTitle>
        <Box display="flex" gap="1em" >
          <Typography> { translate("vc_validator.show_rules.description") }</Typography>
          <IconButton sx={{ width: "50px", height: "50px" }} aria-label="close" onClick={handleClose} > <CloseIcon /> </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box id="show-rules">
          <FunctionField render={_ => record.directives} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{m: 1 }}
          fullWidth
          startIcon={<LinkIcon />}
          onClick={() => copyToClipboard(record.directives)}
          variant="contained"
          id="copy-rules"
          >
          {translate("vc_validator.show_rules.copy_to_clipboard")}
        </Button>
      </DialogActions>
    </Dialog>
  </Box>);
}



function RuleList({permissions}) {
  const translate = useTranslate();
  const isOpen = localStorage.getItem("ruleListOpen");
  const [open, setOpen] = useSafeSetState(true);

  useEffect(() => {
    isOpen !== null && setOpen(isOpen === "true");
  }, [])

  return (
    <Card sx={{mt: "2em"}} id="rule-list">
      <CardTitleWithHideButton open={open} setOpen={setOpen} storage="ruleListOpen" text="vc_validator.rule_list.title" />
      {open && 
        <List
          empty={<Box sx={{m: 1}}>{ translate("vc_validator.rule_list.empty_message") }</Box>}
          resource="Rule"
          sort={{ field: 'id', order: 'DESC' }}
          filters={ruleFilters}
          filter={{archivedAtIsSet: false}}
          pagination={<PaginationDashboard />}
          actions={false}
          disableSyncWithLocation
        >
          <RuleDataGrid permissions={permissions} />
        </List>
      }
    </Card>
  );
}



const RuleDataGrid = ({permissions}: {permissions?: string}) => {
  return <Datagrid
    bulkActionButtons={false}
    sx={{ '& .column-name': { width: "100%" } }}
  >
    <TextField source="id" sortable={false} />
    <Box source="name" label="resources.Rule.many" display="flex" alignItems="center" flexWrap="wrap" gap="1em">
      <Box flex="2" minWidth="200px">
        <TextField source="name" sortable={false} />
      </Box>
      <ShowRules />
      {requirePermissions.canDeleteRules(permissions) &&
        <ArchiveResourceAsUpdate resource="Rule" />
      }
    </Box>
  </Datagrid>;
}


export { Dashboard, WerifyPointDatagrid, RuleDataGrid };