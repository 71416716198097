import { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import {
  useSafeSetState, useGetList, useTranslate
} from 'react-admin';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import { NoPermissionsLayout } from './no_permissions';
import _ from 'lodash';
import requirePermissions from '../lib/permissions';
import Loading from './loading';
import QRCode from "react-qr-code";
import { Head2 } from '../theme';
import { Settings } from '../Settings';
import { FavWerifyPoint } from '../components/fav_werify_point_action';


const WerifyPointFavorite = () => {
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);
  const {data: werifyPoints, isLoading} = useGetList("WerifyPoint", {filter: {favoriteEq: true}});

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  if (isLoading || loading) return <Loading />;
  if (!requirePermissions.canCreateWerifyPoint(permissions)) { return <NoPermissionsLayout /> };
  if (werifyPoints.length === 0) return <NoWerifyPoints werifyPoints={werifyPoints} />

  return <Container maxWidth="md" id="werify_point_favorites">
    <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" >
    {werifyPoints.map(m => (<Box
          key={`werify-point-${m.id}`}
          id={`werify-point-${m.id}`}
          display="flex"
          flexDirection="column"
          border="1px solid #d0d0d0"
          borderRadius= "0.2rem"
          boxShadow= "2px 2px 2px 1px #bbb"
          margin="1.2em"
          minWidth="15em"
          minHeight="20em"
        >
          <a href={m.fullUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#d0d0d0"
              minHeight="13em"
              maxWidth="15em"
              textAlign="center"
              p="1em"
              sx={{ overflowWrap: 'break-word' }}
            >
              <Head2>{m.name}</Head2>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2em"
            >
              <QRCode
                size={70}
                data-url={ Settings.apiHost }
                value={ Settings.apiHost }
                viewBox={`0 0 40 40`}
            />
          </Box>
        </a>
      </Box>
    ))}
    </Box>
  </Container>
}

const NoWerifyPoints = () => {
  const translate = useTranslate();
  return <Box
    id="no-werify-points-in-favorite"
    display="flex"
    justifyContent="center"
    alignItems="center"
    margin="2em"
  >
    {translate("vc_validator.fav_werify_point.no_wp_in_favorites")}
  </Box>
}


export default WerifyPointFavorite;
