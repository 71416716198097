import { ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material';
import { useTranslate } from 'react-admin';
import vidwalletLogo from '../assets/partners_logos/vidwallet_logo.png';
import altmeLogo from '../assets/partners_logos/altme_logo.png';
import talaoLogo from '../assets/partners_logos/talao_logo.png';


const WALLETS = [
  {id: "ALTME", logo: altmeLogo},
  {id: "TALAO", logo: talaoLogo},
  {id: "VIDWALLET", logo: vidwalletLogo},
]

const SelectWallet = ({value, setValue, horizontal = false}) => {
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    newValue && setValue(newValue);
  };
  let orientation = (horizontal || isSmall) ? "horizontal" : "vertical";

  return (
    <ToggleButtonGroup
      orientation={orientation}
      value={value}
      exclusive
      selected={true}
      onChange={handleChange}
      sx={{my: ".5em"}}
    >
      {WALLETS.map(w => {
        const translation = translate(`resources.Attempt.fields.wallets.${w.id}`);
        return <ToggleButton key={w.id} id={`select-${w.id}`} value={w.id} aria-label={translation}>
          <img src={w.logo} alt={translation} style={{ width: 24, height: 24 }} />
          <Typography m=".5em">{translation}</Typography>
      </ToggleButton>
      })}
    </ToggleButtonGroup>
  );
}

export default SelectWallet;
export {WALLETS};
