import { useEffect, useCallback } from 'react';
import { Container, Button, Typography, Box } from '@mui/material';
import { useTranslate, useSafeSetState, useRedirect, Form, useNotify } from 'react-admin';
import { LiveHelp, Replay } from '@mui/icons-material';
import { Head1 } from '../theme';
import { ToolbarLayout } from './layout';
import { makeVidconnectUrl } from "../lib/auth_provider";
import Loading, { CustomLoading } from './loading';
import { base_url } from '../lib/data_provider';
import AltmeLogin from '../components/altme_login';
import _ from 'lodash';
import LoginButton from '../components/login_button';
import ClientIdInput from '../components/email_login_input';
import { Settings } from '../Settings';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { ErrorLoginMessage } from '../views/vidchain_redirect';


const Login = () => {
  const translate = useTranslate();
  const [orgAttrs, setOrgAttrs] = useSafeSetState(null);
  const [loadingPage, setLoadingPage] = useSafeSetState(true);
  const [loadingAction, setLoadingAction] = useSafeSetState(false);
  const [tokenRecaptcha, setTokenRecaptcha] = useSafeSetState(false);
  const [showAltmeComponent, setShowAltmeComponent] = useSafeSetState(false);
  const [disableInputEmail, setDisableInputEmail] = useSafeSetState(false);
  const [error, setError] = useSafeSetState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  const controller = new AbortController();

  useEffect(() => {
    const loadOrg = async () => {
      let emailAddress = localStorage.getItem("emailAddress");
      if (!!emailAddress) {
        try {
          let response = await fetch(`${base_url}/orgs_lookup/${emailAddress}`);
          if (!response.ok) { return; }
          const attrs = await response.json();
          setOrgAttrs(attrs);
        } catch { }
      }

      setLoadingPage(false);
    }
    loadOrg();

    return () => { controller.abort() };
  }, []);

  useEffect(() => {
    !orgAttrs?.clientId && setShowAltmeComponent(false);
  }, [orgAttrs?.clientId])

  const handleLogin = async (values: any) => {
    setLoadingAction(true);
    setShowAltmeComponent(false);
    let emailAddress = values.emailAddress.trim().toLowerCase();
    let attrs = null;
    try {
      let response = await fetch(`${base_url}/orgs_lookup/${emailAddress}`);
      if (!response.ok) {
        setOrgAttrs(null);
        setLoadingAction(false);
        return notify("components.login.user_not_found", { type: 'error' });
      }
      attrs = await response.json();
      setOrgAttrs(attrs);
    } catch {
      setOrgAttrs(null);
    }

    if (attrs) {
      localStorage.setItem("clientId", attrs.clientId);
      localStorage.setItem("emailAddress", attrs.emailAddress);
      if (attrs.wallet === "Vidwallet") {
        let url = makeVidconnectUrl(attrs.clientId, attrs.vidconnectClientId);
        return window.open(url, "_self");
      } else {
        setShowAltmeComponent(true);
      }
    }
  }

  const onVerify = useCallback(async (token) => {
    setTokenRecaptcha(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (loadingPage) {
    return <Loading/>
  }

  return (
    <ToolbarLayout
      loggedIn={false}
      logoUrl={ null }
      fgColor={ "#111" }
      bgColor={ "#fafafa" }
      footerHtml={ false }
    >
      <GoogleReCaptchaProvider reCaptchaKey={ Settings?.recaptchaSiteKey }>
        <Container maxWidth="sm" id="login-form-container" sx={{mb: "2em"}}>
        
          {!disableInputEmail && <>
            <Head1 sx={{my: 3}}> { translate("components.login.title") } </Head1>
            <Form onSubmit={handleLogin} noValidate id="add-value-to-attribute-list" >
              <ClientIdInput
                orgAttrs={orgAttrs}
                setOrgAttrs={setOrgAttrs}
                setLoadingAction={setLoadingAction}
              />
              <LoginButton sx={{mb: "2em"}} type="submit" />
          </Form> 
          </>}
             

          <Box display="flex" justifyContent="center" alignItems="center" flexDirection={error ? "column" : "row"} marginBottom={3}>
              { loadingAction && <CustomLoading height="10vh" /> }
              { showAltmeComponent && !error && <AltmeLogin
                orgAttrs={orgAttrs}
                tokenRecaptcha={tokenRecaptcha}
                setLoadingAction={setLoadingAction}
                loadingAction={loadingAction}
                onVerify={onVerify}
                setDisableInputEmail={setDisableInputEmail}
                setError={setError}
              /> }
              {error && <ErrorLoginAltme error={error} />}
          </Box>
          <Button
            id="get_help"
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => redirect("/help")}
            startIcon={<LiveHelp />}
          >
            { translate("components.login.contact_us") }
          </Button>
        </Container>
        <GoogleReCaptcha onVerify={onVerify} />
      </GoogleReCaptchaProvider>
    </ToolbarLayout>
  );
};


const ErrorLoginAltme = ({error}) => {
  const translate = useTranslate();

  return <>
    <ErrorLoginMessage error={error} />
    <Button
        sx={{mb: 2}}
        fullWidth
        variant="contained"
        onClick={() => window.location.reload()}
        startIcon={<Replay />}
      >
        { translate("components.login.try_again") }
      </Button>
  </>
}


export default Login;
