import { useEffect, useCallback } from 'react';
import { Box, Container, Button, TextField, Typography, Alert } from '@mui/material';
import {
  useSafeSetState, Form, useTranslate, useNotify, useRedirect
} from 'react-admin';
import { NoLoggedInLayout } from './layout';
import _ from "lodash";
import { base_url } from '../lib/data_provider';
import { Settings } from '../Settings';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import Loading from './loading';
import { useLocation, useNavigate } from 'react-router-dom';
import ClickWallet from '../components/click_wallet';


const Help = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const notify = useNotify();
  const [error, setError] = useSafeSetState(null);
  const [recaptchaCode, setRecaptchaCode] = useSafeSetState(null);
  const [helpSettings, setHelpSettings] = useSafeSetState({});
  const [emailAddress, setEmailAddress] = useSafeSetState(null);
  const [attemptId, setAttemptId] = useSafeSetState(null);
  const [customMessage, setCustomMessage] = useSafeSetState("");
  const [finished, setFinished] = useSafeSetState(false);

  useEffect(() => {
    const getKioskUrl = async () => {
      const helpSettings = (await (await fetch(`${base_url}/help_lookup`)).json());
      setHelpSettings(helpSettings);
    }
    getKioskUrl();
  }, [])

  useEffect(() => {
    const decode = async () => {
      try {
        const url = `${base_url}/help_lookup/validate?${new URLSearchParams({token}).toString()}`;
        const decoded = (await (await fetch(url)).json());

        setAttemptId(decoded.id);
        const stored_values = JSON.parse(decoded.stored_values);
        const email = stored_values.find(obj => obj.pointer === "/credentialSubject/email")?.value;
        if (email) {
          setEmailAddress(email);
        }
      } catch (e) {
        setError(true);
        notify("help.errorMessageReadingAttempt", { type: "error"})
        console.error(e);
      }
    }

    if (token && helpSettings.public_key) {
      decode();
    }
  }, [token, helpSettings.public_key]);

  const updateRecaptchaCode = token => {
    setRecaptchaCode(token)
  }

  const onVerify = useCallback(async (token) => {
    updateRecaptchaCode(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    token,
    emailAddress,
    attemptId,
    customMessage,
    recaptchaCode,
    helpSettings,
    setCustomMessage,
    setFinished
  }

  if (error) return <ErrorContainer />
  if (finished) return <FinishedContainer />

  return <NoLoggedInLayout>
    {attemptId ?
    <GoogleReCaptchaProvider reCaptchaKey={ Settings?.recaptchaSiteKey }>
      <MessageContainer {...props} /> 
      <GoogleReCaptcha onVerify={onVerify} />
    </GoogleReCaptchaProvider>
    :
      <MessageContainer {...props} /> 
    }
    </NoLoggedInLayout>
}

const MessageContainer = ({token, emailAddress, attemptId, customMessage, recaptchaCode, helpSettings, setCustomMessage, setFinished}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [name, setName] = useSafeSetState("");
  const [company, setCompany] = useSafeSetState("");

  const handleSubmit = async (values) => {
    if (!emailAddress ||!attemptId) {
      return notify("help.needToPresentCredential", { type: "error"} );
    }
    if (!name) {
      return notify("help.needToCompleteName", { type: "error"} );
    }
    if (!company) {
      return notify("help.needToCompleteCompany", { type: "error"} );
    }
    if (!customMessage || customMessage.length < 10) {
      return notify("help.needToCompleteCustomMessage", { type: "error"} );
    }

    const response = (await (await fetch(`${base_url}/help_lookup/send_message`, {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({
        email_address: emailAddress,
        name,
        company,
        attempt_id: attemptId,
        custom_message: customMessage,
        recaptcha_code: recaptchaCode
      }),
    })));

    if (response.status === 200) {
      setFinished(true);
    } else {
      notify("help.errorMessageSendingMessage", { type: "error"} );
    }
  }

  if (token && !attemptId) return <Loading />
  
  return <Container maxWidth="md" id="help-container">
    <Box textAlign="center" width="100%" height="auto">
      {!emailAddress &&
        <Alert id="alert-info-wallet" severity="info" sx={{textAlign: "left"}}>
          <Typography sx={{mb: ".5em"}}>
            {translate("help.info_1")}&nbsp;
            <a href="https://altme.io/" target="_blank" rel="noreferrer">Altme</a>,&nbsp;
            <a href="https://talao.io/talao-wallet/" target="_blank" rel="noreferrer">Talao</a>&nbsp;
            {translate("help.info_o")}&nbsp;
            <a href="https://issuer.vidchain.net/" target="_blank" rel="noreferrer">Vidwallet</a>.
          </Typography>
          <Typography>{translate("help.info_2")}</Typography>
        </Alert>
      }
      <Form onSubmit={handleSubmit} noValidate id="help-form"> 
        {emailAddress ?
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField id="emailAddress" fullWidth label={translate("help.selected_vc")} disabled value={emailAddress} sx={{flex: 6}} required={true}/>
              <Button variant="contained" onClick={() => {redirect("/help"); window.location.reload()}} sx={{m: ".5em"}}>
                {translate("help.select_another_credential")}
              </Button>
            </Box>
            :
            <>
              <Typography textAlign="left" sx={{mt: "1.5em"}}>{translate("help.select_credential")}</Typography>
              <ClickWallet helpSettings={helpSettings} />
            </>
        }
        
        <TextField
          id="name"
          fullWidth
          sx={{flex: 6}}
          label={translate("help.name")} 
          disabled={!emailAddress}
          value={name}
          required={true}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          id="company"
          fullWidth
          sx={{flex: 6}}
          label={translate("help.company")}
          disabled={!emailAddress}
          value={company}
          required={true}
          onChange={e => setCompany(e.target.value)}
        />
        <TextField
          id="message"
          sx={{ '& textarea': { fontFamily: 'monospace', fontSize: "0.9em", lineHeight: "1em" }, m: "1em 0 2em 0" }}
          onChange={e => setCustomMessage(e.target.value)}
          value={customMessage}
          variant="outlined"
          multiline
          fullWidth
          minRows={15}
          maxRows={50}
          label={emailAddress ? translate("help.email_message") : translate("help.needToPresentCredential")}
          disabled={!emailAddress}
          required={true}
        />
        <Button fullWidth variant="contained" type="submit" disabled={!emailAddress || !customMessage}>
            {translate("help.send")}
        </Button>

      </Form> 
    </Box>
    <Alert id="alert-wallet-not-included" severity="warning" sx={{my: "1em"}}>
      {translate("help.request_for_new_wallet")}
    </Alert>
    <Alert id="alert-rgpd" severity="warning" sx={{my: "1em"}}>
      {translate("help.RGPD_info")}
    </Alert>
  </Container>
}

const FinishedContainer = () => {
  const translate = useTranslate();
  return <NoLoggedInLayout>
    <Container maxWidth="md" id="help-message-sent">
      <Box id="message-sent" display="flex" justifyContent="center" alignContent="center">
        <Typography>{translate("help.message_sent")}</Typography>
      </Box>
    </Container>

  </NoLoggedInLayout>
}


const ErrorContainer = () => {
  const translate = useTranslate();
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/help");
    window.location.reload();
  };

  return <NoLoggedInLayout>
    <Container maxWidth="md">
      <Box id="message-error" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Typography sx={{m: "3em 0 1em 0"}} color="error">{translate("vc_validator.kiosk.failed")}</Typography>
        <Button variant="contained" onClick={onClick }>
          {translate("ra.action.refresh")}
        </Button>
      </Box>
    </Container>

  </NoLoggedInLayout>
}

export default Help;