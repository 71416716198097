export interface Credentials {
    sessionPublicKey: string | null,
    sessionPrivateKey: string | null,
    environment?: string | null,
}


export const restrictiveFilterKinds = [
  'Exists',
  'StringContains',
  'StringStarts',
  'StringEnds',
  'OlderThanYears',
  'YoungerThanYears',
  'DateAfter',
  'DateBefore',
  'NumberGreaterThan',
  'NumberLesserThan',
  'StringMatches',
  'ArrayContains',
  'DidIs',
];

export const extensiveFilterKinds = [
  ...restrictiveFilterKinds,
  'InDidList',
  'InAttributeList'
]
