import merge from 'lodash/merge';
import { styled } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';
import PoppinsSemiBold from "./assets/Poppins/Poppins-SemiBold.ttf";
import PoppinsSemiBoldItalic from "./assets/Poppins/Poppins-SemiBoldItalic.ttf";
import PoppinsLight from "./assets/Poppins/Poppins-Light.ttf";
import PoppinsLightItalic from "./assets/Poppins/Poppins-LightItalic.ttf";

const fontFaces = [
  [PoppinsSemiBold, "SemiBold", 600],
  [PoppinsSemiBoldItalic, "SemiBoldItalic", 600],
  [PoppinsLight, "Light", 300],
  [PoppinsLightItalic, "LightItalic", 300],
].map((font) => 
  `@font-face {
    font-family: 'Poppins${font[1]}';
    font-weight: ${font[2]};
    src: local('Poppins'), local('Poppins-${font[1]}'), url("${font[0]}") format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }`
).join("\n");

const lightGrey = "#bbb";
const darkGrey = "#999";
const darkerGrey = "#444";

export default merge({}, defaultTheme, {
  typography: {
    fontFamily: '"PoppinsLight", sans-serif',
  },
  palette: {
    primary: {
      main: darkerGrey,
      light: '#fff',
      contrastText: lightGrey,
      otherContrastText: '#000',
    },
    secondary: {
      main: darkGrey,
      light: '#dddddf',
    },
    inverted: {
      main: lightGrey,
      light: '#ccc',
      contrastText: '#333',
      otherContrastText: '#444',
    },
    highlight: {
      main: '#1f4668',
      light: '#fff',
      contrastText: '#fff',
      otherContrastText: '#000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: fontFaces,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "0.2rem !important",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: darkGrey,
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        spacing: {
          margin: "0 1em 2em 1em ",
          padding: 0,
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "1px",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          textTransform: "none",
          fontWeight: "400",
        },
        contained: {
          boxShadow: "none",
        },
        outlined: {
          border: "2px solid",
          '&:hover': {
            borderWidth: "2px",
          },
        },
      }
    },
    RaListToolbar: {
      styleOverrides: {
        root: {
          marginBottom: "1em",
          marginTop: "1em",
        },
      }
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          marginLeft: "1em",
        },
      }
    },
    RaList: {
      styleOverrides: {
        root: {
          "overflow": "auto",
          "& .RaList-content": {
            borderRadius: "0 !important",
            borderWidth: "0",
            boxShadow: "none !important",
          }
        },
      }
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          "& .RaSimpleShowLayout-stack": {
            flexDirection: "row",
            flexWrap: "wrap",
          },
          "& .RaSimpleShowLayout-row": {
            borderRadius: "4px",
            background: "#f0f0f0",
            padding: "0.5em",
            margin: "0.5em 0.5em 0 0",
          }
        },
      }
    },
    RaShow: {
      styleOverrides: {
        root: {
          "& pre": {
            whiteSpace: "break-spaces",
          },
          "& .RaShow-card": {
            borderRadius: "0 !important",
            borderWidth: "0",
            boxShadow: "none !important",
          }
        },
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .column-createdAt": {
            minWidth: 130,
          },
          "& .column-copyLink": {
            display: "block",
            minWidth: "100px",
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#fff",
          boxShadow: "none"
        },
      }
    }
  },
});

export const Head1 = styled("h1")(({ theme }) => ({
  fontFamily: "'PoppinsSemiBold'",
  fontSize: "40px",
  letterSpacing: "-0.03em",
  [theme.breakpoints.up('md')]: {
    fontSize: "60px",
  },
  margin: "0",
}));

export const Head2 = styled("h2")(({ theme }) => ({
  fontFamily: "'PoppinsSemiBold'",
  fontSize: "19px",
  letterSpacing: "-0.03em",
  [theme.breakpoints.up('md')]: {
    fontSize: "23px",
  },
  margin: 0,
}));

declare module '@mui/material/styles' {
  interface PaletteOptions {
    highlight?: {
      main?: string;
      light?: string;
      contrastText?: string;
      otherContrastText?: string;
    },
    inverted?: {
      main?: string;
      light?: string;
      contrastText?: string;
      otherContrastText?: string;
    };
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    highlight: true;
    inverted: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    highlight: true;
    inverted: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    highlight: true;
    inverted: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    highlight: true;
    inverted: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    highlight: true;
    inverted: true;
  }
}
