import { Box, Container, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { KioskLogo } from './werify_point_kiosk';


const LayoutAccessToken = ({id, resource, children, flexDirection}) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const bgColor = resource?.backgroundColor || "#001E35";
  const fgColor = resource?.foregroundColor || "#00FFFF";
  const footerHtml = resource?.footerHtml || false;
  const logoUrl = resource?.logoUrl || false;

  return (<Box display="flex" flexDirection="column" overflow="auto" minHeight="100vh" height="auto">
    <CssBaseline/>
    <Box display="flex" backgroundColor={bgColor} foregroundcolor={fgColor} padding=".7em">
      <Container sx={{display: "flex", justifyContent: "center"}}>  
      { logoUrl && <KioskLogo logo={logoUrl} isMobile={isMobile}/> }
      </Container>
    </Box>
    <Container id={id} sx={{
        p: "1em",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: isMobile ? "80vh" : "70vh",
      }}
    >
      <Box display="flex" justifySelf="center" alignSelf="center" flexDirection={flexDirection}>
        {children}
      </Box>
    </Container>
    <Box display="flex" backgroundColor={bgColor} foregroundcolor={fgColor} padding=".7em">
        <Container style={{ padding: 0}}>
          { footerHtml && <div dangerouslySetInnerHTML={{__html: footerHtml}} /> }
        </Container>
      </Box>
    </Box>);
}


export {LayoutAccessToken};
