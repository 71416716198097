import _ from 'lodash';

const requirePermissions: any = {
    canReadBasicResources: (permissions) => {
      return _.includes(["ADMIN", "MANAGER", "AUDITOR", "OPERATOR", "AGENT"], permissions)
    },
    canReadRules: (permissions) => {
      return _.includes(["ADMIN", "MANAGER", "AUDITOR"], permissions)
    },
    canCreateWerifyPoint: (permissions) => {
      return _.includes(["ADMIN", "MANAGER", "OPERATOR"], permissions)
    },
    canCreateRule: (permissions) => {
      return _.includes(["ADMIN"], permissions)
    },
    canDeleteWerifyPoints: (permissions) => {
      return _.includes(["ADMIN", "MANAGER", "OPERATOR"], permissions)
    },
    canDeleteRules: (permissions) => {
      return _.includes(["ADMIN"], permissions)
    },
    canReadAdminResources: (permissions) => {
      return _.includes(["ADMIN", "MANAGER", "AUDITOR"], permissions)
    },
    canWriteAdminResources: (permissions) => {
      return _.includes(["ADMIN"], permissions)
    }
}

export default requirePermissions;
