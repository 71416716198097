import { SelectInput } from 'react-admin';
import { restrictiveFilterKinds } from '../lib/types';

const SelectFilter = ({source, validate, alwaysOn}: {source: string, validate?: any, alwaysOn?: boolean}) => {
  const choices = restrictiveFilterKinds.map((v) => ({
    id: v,
    name: `resources.KnownAttribute.fields.filters.${v}`
  }));
  
  return <SelectInput source={source} validate={validate} alwaysOn={alwaysOn} choices={choices} />
};

export default SelectFilter;
