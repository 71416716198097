import {
  List, Datagrid, TextField, ShowButton, Show, SimpleShowLayout, DateField,
  ReferenceField, TopToolbar
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";

function EmailList() {

  const emailFilters = [
    <FilterTextInput source="addressLike" alwaysOn />,
    <FilterTextInput source="personIdEq" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={emailFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='address'/>
        <ReferenceField source="personId" reference="Person" link="show">
          <TextField source="id" />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} showDate={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
}


function EmailShow() {
  return (
    <Show>
      <SimpleShowLayout >
        <TextField source='address'/>
        <ReferenceField source="personId" reference="Person" link="show">
          <TextField source="id" />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} showDate={true} />
      </SimpleShowLayout>
    </Show>
  );
}

export {EmailList, EmailShow};