import { SelectInput } from 'react-admin';

const ATTEMPT_STATES = [
  "APPROVED",
  "REJECTED",
  "FAILED"
]

const SelectAttemptState = ({source, validate, alwaysOn}: {source: string, validate?: any, alwaysOn?: boolean}) => {
  const choices = ATTEMPT_STATES.map((state) => ({
    id: state,
    name: `resources.Attempt.fields.states.${state}`
  }));
  
  return <SelectInput source={source} validate={validate} alwaysOn={alwaysOn} choices={choices} />
};

export default SelectAttemptState;