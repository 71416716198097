import CSVFileInputGeneric from './csv_file_input_generic'; // Asegúrate de que esta es la ruta correcta al componente
import _ from "lodash";
import { useNotify, useRefresh } from 'react-admin';

function CSVFileInputAttributeList({ id, setLoading, buttonLabel, disabled, mutationFunction }) {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleFileProcessing = async (results) => {
    const fields = results?.meta?.fields;
    if (!_.includes(fields, "value") ) {
      notify(`resources.AttributeList.errorUpdating`, { type: 'error' });
      return;
    }
    const valuesArray = results.data.map(v => v.value);
    try {
      await mutationFunction(valuesArray);
      refresh();
    } catch (e) {
      notify(`resources.AttributeList.errorUpdating`, { type: 'error' });
    }
  };

  return (
    <CSVFileInputGeneric
      id={id}
      setLoading={setLoading}
      buttonLabel={buttonLabel}
      handleFileProcessing={handleFileProcessing}
      additionalDisabledCondition={disabled}
    />
  );
}

export default CSVFileInputAttributeList;