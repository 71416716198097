
import { Button } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useNavigate } from "react-router-dom";
import { LiveHelp } from '@mui/icons-material';

const HelpButton = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
    return <Button
      id="get_help"
      variant="outlined"
      size="large"
      fullWidth
      onClick={() => navigate("/help")}
      startIcon={<LiveHelp />}
      >
      { translate("components.login.contact_us") }
    </Button>
}

export default HelpButton;