import { useEffect, useCallback } from 'react';
import { Box, CircularProgress, Button, useMediaQuery, Alert } from '@mui/material';
import QRCode from "react-qr-code";
import { base_url } from '../lib/data_provider';
import { makeKeys, setAuthKeys } from '../lib/auth_provider';
import { useSafeSetState, useTranslate } from 'react-admin';
import { Head2 } from "../theme";
import { useNavigate, useLocation } from 'react-router-dom';
import { Settings } from '../Settings';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const createAltmeUrl = (sessionPublicKey, clientId) => {
  const url = "openid-vc://";
  const paramsTwo = new URLSearchParams({
    client_id: clientId,
    request_uri: `${Settings.altmeRedirectUri}/altme_login/request_uri?client_id=${clientId}&session_public_key=${sessionPublicKey}`,
    state: sessionPublicKey,
  });
  return `${url}?${paramsTwo.toString()}`;
}

type State = {
  keys?: any,
  pubkeyBase64?: String,
  altmeUrl?: String,
  token?: String,
}

type Session = {
  exists: Boolean,
  state?: String,
  errors?: String,
}

const AltmeLogin = ({orgAttrs, tokenRecaptcha, onVerify, setLoadingAction, loadingAction, setDisableInputEmail, setError}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const queryParams = new URLSearchParams(location.search);
  const responseCode = queryParams.get('response_code');
  const [loadingResponseCode, setLoadingResponseCode] = useSafeSetState(!!responseCode);
  const [state, setState] = useSafeSetState<State>({});
  const [session, setSession] = useSafeSetState<Session>({exists: false});
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const controller = new AbortController();

  useEffect(() => {
    const loadResponseCode = async () => {
      setLoadingResponseCode(true);
      try {
        const clientId = orgAttrs.clientId;
        const response = (await (await fetch(`${base_url}/altme_login/response?client_id=${clientId}&response_code=${responseCode}`)).json());
        const publicKey = Buffer.from(response.session_public_key, 'base64').toString('utf-8');      
        const privateKey = Buffer.from(response.session_private_key, 'base64').toString('utf-8');
        const keys = { sessionPrivateKey: privateKey, sessionPublicKey: publicKey };
        setAuthKeys(keys);
        navigate("/");
      } catch {
        navigate("/login");
      }
      setLoadingResponseCode(false);
    }
    
    responseCode && loadResponseCode();
  }, [responseCode])

  const updateState = async (token) => {
    let [status, keys, pubkeyBase64] = await createPendingSession(token);
    if (status === 200) {
      setState({
        keys,
        pubkeyBase64,
        altmeUrl: createAltmeUrl(pubkeyBase64, orgAttrs.clientId),
        token
      })
    }
  }

  const createPendingSession = async (token) => {
    const keys = await makeKeys();
    const clientId = orgAttrs.clientId;
    const pubkeyBase64 = Buffer.from(keys?.sessionPublicKey).toString("base64");
    const response = (await fetch(`${base_url}/altme_login/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_id: clientId,
        recaptcha_code: token,
        session_public_key: pubkeyBase64,
        session_private_key: isMobile ? Buffer.from(keys?.sessionPrivateKey).toString("base64") : null,
        mobile: isMobile,
      })
    }));
    return [response.status, keys, pubkeyBase64];
  }

  useEffect(() => {
    updateState(tokenRecaptcha);
  }, [tokenRecaptcha]);

  useEffect(() => {
    if (loadingAction && session?.state === "Pending") {
      setLoadingAction(false);
    }
  }, [loadingAction])

  useEffect(() => {
    const updateToken = async () => {
      if (!executeRecaptcha) { return; }

      const token = await executeRecaptcha('login');
      onVerify(token); 
    }

    updateToken();
  }, [loadingAction])

  useEffect(() => {
    let interval;
    async function load(){
      if(!state?.keys) { return; }
      
      const session = (await (await fetch(`${base_url}/sessions_lookup/${state.pubkeyBase64}`)).json());
      setSession(session);
      session.state === "Expired" && updateState(state.token);
      session.state === "Completed" && setTimeout(setKeysAndRedirect, 4000);
    }
    interval = setInterval(load, 1000);
    return function cleanup() { clearTimeout(interval); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.keys]);

  const setKeysAndRedirect = () => {
    setAuthKeys(state.keys);
    navigate("/");
  }

  useEffect(() => {
    if (state?.altmeUrl && isMobile) {
      window.open(state?.altmeUrl, '_blank');
      return;
    }
  },[]);

  useEffect(() => {
    state?.altmeUrl && setLoadingAction(false);
    return () => { controller.abort() };
  },[state?.altmeUrl])

  useEffect(() => {
    session?.state === "Failed" && setError(session.errors)
  }, [session?.state])

  if (loadingResponseCode) return;

  let showQr = !session?.exists || session?.state === "Pending";

  return(
      <Box>
        { showQr && <QRComponent altmeUrl={state?.altmeUrl} /> }
        { session?.state === "Completed" && <LogginIn setDisableInputEmail={setDisableInputEmail} /> }
      </Box>
)};

const QRComponent = ({altmeUrl}) => {
  const translate = useTranslate();
  if (!altmeUrl) return;
  return <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
    <Alert severity="warning" sx={{mb: 2}}>{translate("credential_claim.login_altme")}</Alert> 
    <QRCode
      size={100}
      data-altme-url={ altmeUrl }
      style={{ height: "auto", maxWidth: "100%", width: "65%" }}
      value={altmeUrl}
      viewBox={`0 0 150 150`}
    />
  </Box>
}

const LogginIn = ({setDisableInputEmail}) => {
  useEffect(() => {
    setDisableInputEmail(true);
  }, [])

  const translate = useTranslate();
  return <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
    <CircularProgress sx={{mb: 3}}/>
    <Head2>{ translate("components.login.logging_in") }</Head2>
  </Box>
}



export default AltmeLogin;
