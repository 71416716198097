import { useEffect } from 'react';
import {
  List, Datagrid, ShowButton, TextField, TextInput, required, DeleteWithConfirmButton, ReferenceInput, useTranslate,
  AutocompleteInput, FunctionField, Show, SimpleForm, TopToolbar, Create, SimpleShowLayout, useSafeSetState,
  ArrayInput, SimpleFormIterator, ArrayField, ReferenceField, Form, useDataProvider, useNotify, useRefresh
} from 'react-admin'
import { Box, Button } from '@mui/material';
import { PaginationDefault, defaultSort } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";
import { TopToolbarWithCreateButton } from '../components/top_toolbars';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';
import UpdateResourceAction from '../components/update_resource_action';
import { Add } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import _ from "lodash";


function DidListList() {
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const didListFilter = [
    <FilterTextInput source="nameLike" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={didListFilter}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={requirePermissions.canWriteAdminResources(permissions) ? <TopToolbarWithCreateButton/> : <TopToolbar />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' />
        <TextField source='count' sortable={false} />
        <ShowButton />
        {requirePermissions.canWriteAdminResources(permissions) && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
}
 

function DidListShow(){
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { id } = useParams();
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const Toolbar = () => requirePermissions.canWriteAdminResources(permissions) ? <TopToolbar><DeleteWithConfirmButton /></TopToolbar> : <TopToolbar />;

  const optionText = values => {
    if (!values.label || !values.did) return values;
    return `${values.label} - ${values.did}`;
  }

  const handleSubmit = async (values: any) => {
    try {
      await dataProvider.update("DidList", {
        id, data: {action: "add", knownDidIds: [values.id]}
      });
    } catch (e) {
      notify(`resources.DidList.errorUpdating`, { type: 'error' });
    }
    refresh();
  }

  return (
    <Show actions={<Toolbar />} >
      <SimpleShowLayout>
        <TextField source='name' />
        <TextField source='count' />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <ArrayField source='knownDidIds' >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" label="resources.KnownDid.fields.label" reference="KnownDid">
              <TextField source="label" />
            </ReferenceField>
            <ReferenceField source="id" label="resources.KnownDid.fields.did" reference="KnownDid">
              <TextField source="did" />
            </ReferenceField>
            <ReferenceField source="id" label="" reference="KnownDid" link={false}>
              <FunctionField render={record => <UpdateResourceAction resource="DidList" data={{action: "delete", knownDidIds: [record.id]}} />} />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <SimpleShowLayout >
        <FunctionField render={record => {
          return <Form onSubmit={handleSubmit} noValidate id="add-known-did" >
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" width="700px">
              <ReferenceInput source="id" reference="KnownDid" fullWidth filter={{idsNot: record.knownDidIdsIds}} perPage={100}>
                <AutocompleteInput optionText={optionText} fullWidth validate={required()}/>
              </ReferenceInput>
              <Button sx={{mx: "1em", px: "2em"}} startIcon={<Add />} color="info" variant="outlined" size="small" type="submit">
                {translate("resources.DidList.add_to_list")}
              </Button>
            </Box>    
            </Form> 
        }}
        />
      </SimpleShowLayout>
    </Show>
  );
}


const DidListCreate = () => {
  const [dids, setDids] = useSafeSetState([]);
  const [idsNot, setIdsNot] = useSafeSetState([]);

  const WatchDids = () => {
    const knownDidIds = useWatch({ name: 'knownDidIds' });
    if (!_.isEqual(knownDidIds, dids)) {
      setDids(knownDidIds);
      setIdsNot(_.filter(knownDidIds, v => v.id !== null).map(e => e.id));
    }
    return null;
  };

  const transform = values => {
    values.knownDidIds = values.knownDidIds.map(e => e.id);
    return values;
  }

  const optionText = values => {
    return `${values.label} - ${values.did}`;
  }

  return (
    <Create resource="DidList" redirect="show" transform={transform}>
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="name" autoComplete="off" validate={required()} />
          <ArrayInput source="knownDidIds" validate={required()}>
            <SimpleFormIterator fullWidth disableReordering>
              <ReferenceInput source="id" reference="KnownDid" filter={{idsNot}}>
                <AutocompleteInput label="resources.DidList.fields.id" optionText={optionText} validate={required()} fullWidth />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
          <WatchDids />
        </SimpleForm>
    </Create>
  );
}



export {DidListList, DidListShow, DidListCreate};
