import { List, Datagrid, DateField, ShowButton, Show, SimpleShowLayout, ReferenceField, TopToolbar, BooleanField } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";
import MyBooleanFilter from '../components/boolean_filter';
import PersonTextField from '../components/person_textfield';

function TermsAcceptanceList() {

  const termsAcceptanceFilters = [
    <MyBooleanFilter source="acceptedIsSet" resource="TermsAcceptance" alwaysOn={true} />,
    <FilterTextInput source="personIdEq" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={termsAcceptanceFilters}
      filterDefaultValues={{ acceptedIsSet: false }}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar />}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} showDate={true} />
        <BooleanField source="accepted" looseValue={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
}


function TermsAcceptanceShow(){
  return (
    <Show>
      <SimpleShowLayout >
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} showDate={true} />
        <DateField source="accepted" showTime={true} showDate={true} />
      </SimpleShowLayout>
    </Show>
  );
};

export {TermsAcceptanceList, TermsAcceptanceShow};