import { TranslationMessages } from 'ra-core';

const gallegoMessages: TranslationMessages = {
    ra: {
        action: {
            add_filter: 'Engadir filtro',
            add: 'Engadir',
            back: 'Volver',
            bulk_actions: '1 elemento seleccionado |||| %{smart_count} elementos seleccionados',
            cancel: 'Cancelar',
            clear_array_input: 'Limpar a lista',
            clear_input_value: 'Limpar valor',
            clone: 'Clonar',
            confirm: 'Confirmar',
            create: 'Crear',
            create_item: 'Crear %{item}',
            delete: 'Eliminar',
            edit: 'Editar',
            export: 'Exportar',
            list: 'Lista',
            refresh: 'Actualizar',
            remove_filter: 'Eliminar este filtro',
            remove_all_filters: 'Eliminar todos os filtros',
            remove: 'Eliminar',
            save: 'Gardar',
            search: 'Buscar',
            select_all: 'Seleccionar todo',
            select_row: 'Seleccionar esta fila',
            show: 'Mostrar',
            sort: 'Ordenar',
            undo: 'Desfacer',
            unselect: 'Deseleccionar',
            expand: 'Expandir',
            close: 'Pechar',
            open_menu: 'Abrir menú',
            close_menu: 'Pechar menú',
            update: 'Actualizar',
            move_up: 'Subir',
            move_down: 'Baixar',
            open: 'Abrir',
            toggle_theme: 'Cambiar modo claro/escuro',
            select_columns: 'Columnas',
            update_application: 'Recargar Aplicación',
        },
        boolean: {
            true: 'Si',
            false: 'Non',
            null: ' ',
        },
        page: {
            create: 'Crear %{name}',
            dashboard: 'Panel de control',
            edit: '%{name} %{recordRepresentation}',
            error: 'Algo foi mal',
            list: '%{name}',
            loading: 'Cargando',
            not_found: 'Non Encontrado',
            show: '%{name} %{recordRepresentation}',
            empty: 'Aínda non hai %{name}.',
            invite: 'Queres engadir un?',
        },
        input: {
            file: {
                upload_several: 'Solta algúns arquivos para subir, ou fai clic para seleccionar un.',
                upload_single: 'Solta un arquivo para subir, ou fai clic para seleccionalo.',
            },
            image: {
                upload_several: 'Solta algunhas imaxes para subir, ou fai clic para seleccionar unha.',
                upload_single: 'Solta unha imaxe para subir, ou fai clic para seleccionala.',
            },
            references: {
                all_missing: 'Non se puido atopar datos de referencias.',
                many_missing: 'Polo menos unha das referencias asociadas xa non parece estar dispoñible.',
                single_missing: 'A referencia asociada xa non parece estar dispoñible.',
            },
            password: {
                toggle_visible: 'Ocultar contrasinal',
                toggle_hidden: 'Mostrar contrasinal',
            },
        },
        message: {
            about: 'Acerca de',
            are_you_sure: 'Estás seguro?',
            auth_error: 'Ocorreu un erro ao validar o token de autenticación.',
            bulk_delete_content: 'Estás seguro de querer eliminar este %{name}? |||| Estás seguro de querer eliminar estes %{smart_count} elementos?',
            bulk_delete_title: 'Eliminar %{name} |||| Eliminar %{smart_count} %{name}',
            bulk_update_content: 'Estás seguro de querer actualizar este %{name}? |||| Estás seguro de querer actualizar estes %{smart_count} elementos?',
            bulk_update_title: 'Actualizar %{name} |||| Actualizar %{smart_count} %{name}',
            clear_array_input: 'Estás seguro de querer limpar toda a lista?',
            delete_content: 'Estás seguro de querer eliminar este elemento?',
            delete_title: 'Eliminar %{name} #%{id}',
            details: 'Detalles',
            error: "Ocorreu un erro do cliente e a túa solicitude non puido ser completada.",
            invalid_form: 'O formulario non é válido. Por favor, comproba os erros',
            loading: 'Por favor, espera',
            no: 'Non',
            not_found: 'Ou ben escribiches un URL incorrecto, ou seguiches unha mala ligazón.',
            yes: 'Si',
            unsaved_changes: "Algúns dos teus cambios non foron gardados. Estás seguro de querer ignorarlos?",
        },
        navigation: {
            no_results: 'Non se atoparon resultados',
            no_more_results: 'O número de páxina %{page} está fóra dos límites. Proba a páxina anterior.',
            page_out_of_boundaries: 'Número de páxina %{page} fóra dos límites',
            page_out_from_end: 'Non se pode ir despois da última páxina',
            page_out_from_begin: 'Non se pode ir antes da páxina 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
            partial_page_range_info: '%{offsetBegin}-%{offsetEnd} de máis de %{offsetEnd}',
            current_page: 'Páxina %{page}',
            page: 'Ir á páxina %{page}',
            first: 'Ir á primeira páxina',
            last: 'Ir á última páxina',
            next: 'Ir á seguinte páxina',
            previous: 'Ir á páxina anterior',
            page_rows_per_page: 'Filas por páxina:',
            skip_nav: 'Saltar ao contido',
        },
        sort: {
            sort_by: 'Ordenar por %{field} %{order}',
            ASC: 'ascendente',
            DESC: 'descendente',
        },
        auth: {
            auth_check_error: 'Por favor, inicia sesión para continuar',
            user_menu: 'Perfil',
            username: 'Usuario',
            password: 'Contrasinal',
            sign_in: 'Iniciar sesión',
            sign_in_error: 'Fallou a autenticación, por favor, téntao de novo',
            logout: 'Pechar sesión',
        },
        notification: {
            updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados',
            created: 'Elemento creado',
            deleted: 'Elemento eliminado |||| %{smart_count} elementos eliminados',
            bad_item: 'Elemento incorrecto',
            item_doesnt_exist: 'O elemento non existe',
            http_error: 'Erro de comunicación co servidor',
            data_provider_error: 'Erro de dataProvider. Comproba a consola para máis detalles.',
            i18n_error: 'Non se poden cargar as traducións para o idioma especificado',
            canceled: 'Acción cancelada',
            logged_out: 'A túa sesión terminou, por favor, volve a conectar.',
            not_authorized: 'Non tes autorización para acceder a este recurso.',
            application_update_available: 'Hai unha nova versión dispoñible.',
        },
        validation: {
            required: 'Requirido',
            minLength: 'Debe ter polo menos %{min} caracteres',
            maxLength: 'Debe ter %{max} caracteres ou menos',
            minValue: 'Debe ser polo menos %{min}',
            maxValue: 'Debe ser %{max} ou menos',
            number: 'Debe ser un número',
            email: 'Debe ser un correo electrónico válido',
            oneOf: 'Debe ser un de: %{options}',
            regex: 'Debe coincidir cun formato específico (regexp): %{pattern}',
            unique: 'Debe ser único',
        },
        saved_queries: {
            label: 'Consultas gardadas',
            query_name: 'Nome da consulta',
            new_label: 'Gardar a consulta actual...',
            new_dialog_title: 'Gardar a consulta actual como',
            remove_label: 'Eliminar consulta gardada',
            remove_label_with_name: 'Eliminar consulta "%{name}"',
            remove_dialog_title: 'Eliminar consulta gardada?',
            remove_message: 'Estás seguro de querer eliminar ese elemento da túa lista de consultas gardadas?',
            help: 'Filtra a lista e garda esta consulta para máis tarde',
        },
        configurable: {
            customize: 'Personalizar',
            configureMode: 'Configurar esta páxina',
            inspector: {
                title: 'Inspector',
                content: 'Pasa o rato polos elementos da UI da aplicación para configuralos',
                reset: 'Restablecer Configuracións',
                hideAll: 'Ocultar Todo',
                showAll: 'Mostrar Todo',
            },
            Datagrid: {
                title: 'Cuadrícula de Datos',
                unlabeled: 'Columna sen etiquetar #%{column}',
            },
            SimpleForm: {
                title: 'Formulario',
                unlabeled: 'Entrada sen etiquetar #%{input}',
            },
            SimpleList: {
                title: 'Lista',
                primaryText: 'Texto primario',
                secondaryText: 'Texto secundario',
                tertiaryText: 'Texto terciario',
            },
        },
    },
};

export default gallegoMessages;