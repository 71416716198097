
import { Button } from '@mui/material';
import { useTranslate } from 'react-admin';
import { Replay } from '@mui/icons-material';

const TryAgainButton = ({sx, onClick}: {sx?: any, onClick: any}) => {
  const translate = useTranslate();
    return <Button
      sx={sx}
      variant="contained"
      fullWidth
      onClick={() => onClick()}
      startIcon={<Replay />}
    >
      { translate("components.login.try_again") }
    </Button>
}

export default TryAgainButton;