import {
  List, Datagrid, ShowButton, TextField, Show, TopToolbar, SimpleShowLayout, ReferenceField, DateField,
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import PersonTextField from '../components/person_textfield';
export const PersonIcon = PermIdentity;


function SessionList() {

  const sessionFilters = [
  <FilterTextInput source="personIdEq" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={sessionFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar />}
    >
      {sessionGrid}
    </List>
  );
}

const sessionGrid = 
  <Datagrid bulkActionButtons={false}>
    <ReferenceField source="personId" reference="Person" link="show">
      <PersonTextField source="id" />
    </ReferenceField>
    <TextField source='id' />
    <DateField source="createdAt" showTime={true} showDate={true}/>
    <ShowButton />
  </Datagrid>


function SessionShow(){

  return (
  <Show>
  <SimpleShowLayout>
      <ReferenceField source="personId" reference="Person" link="show">
        <PersonTextField source="id" />
      </ReferenceField>
      <TextField source='id' />
      <DateField source="createdAt" showTime={true} showDate={true}/>
  </SimpleShowLayout>
  </Show>
  );
}




export {SessionList, SessionShow};
