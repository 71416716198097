import { useEffect } from 'react';
import {
  List, Datagrid, ShowButton, TextField, TextInput, required, DeleteWithConfirmButton, ReferenceInput, useTranslate,
  useRedirect, FunctionField, Show, SimpleForm, TopToolbar, Create, SimpleShowLayout, useSafeSetState,
  ArrayInput, SimpleFormIterator, ArrayField, ReferenceField, Form, useDataProvider, useNotify, useRefresh
} from 'react-admin'
import { Box, Button, Container, Divider } from '@mui/material';
import { PaginationDefault, defaultSort, downloadCSV } from "../components/utils";
import FilterTextInput from "../components/filter_textinput";
import { TopToolbarWithCreateButton } from '../components/top_toolbars';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';
import UpdateResourceAction from '../components/update_resource_action';
import { Add, Download } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import _ from "lodash";
import Loading from './loading';
import CSVFileInputAttributeList from '../components/csv_file_input_attribute_list';


function AttributeListList() {
  const [permissions, setPermissions] = useSafeSetState("");

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const attributeListFilter = [
    <FilterTextInput source="labelLike" alwaysOn />,
    <FilterTextInput source="pointerLike" alwaysOn />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={attributeListFilter}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={requirePermissions.canWriteAdminResources(permissions) ? <TopToolbarWithCreateButton/> : <TopToolbar />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='label' />
        <TextField source='pointer' />
        <TextField source='count' sortable={false} />
        <ShowButton />
        {requirePermissions.canWriteAdminResources(permissions) && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
}
 

function AttributeListShow(){
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { id } = useParams();

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const Toolbar = () => requirePermissions.canWriteAdminResources(permissions) ? <TopToolbar><DeleteWithConfirmButton /></TopToolbar> : <TopToolbar />;

  const handleSubmit = async (values: any) => {
    try {
      await dataProvider.update("AttributeList", {
        id, data: {action: "add", attributeValues: [values.value]}
      });
    } catch (e) {
      notify(`resources.AttributeList.errorUpdating`, { type: 'error' });
    }
    refresh();
  }

  const handleDownloadValues = async attributeListId => {
    try {
      const {data: attributeList} = await dataProvider.getOne('AttributeList', {id: attributeListId});
      const finalAttributes = _.map(attributeList.values, a => _.pick(a, ['value']));
      downloadCSV(finalAttributes, "attribute_list_values");
    } catch (e) {
      notify('admin.errors.default', {type: 'warning'});
    }
  };

  const mutationFunction = async valuesArray => {
    await dataProvider.update("AttributeList", {
      id,
      data: { action: "add", attributeValues: valuesArray },
      previousData: {}
     });
  }

  if (loading) return <Loading />

  return (
    <Show actions={<Toolbar />} >
      <SimpleShowLayout>
        <TextField source='label' />
        <TextField source='pointer' />
        <TextField source='count' />
        <FunctionField render={record => <Button startIcon={<Download />} id="download-values" onClick={() => handleDownloadValues(record.id)}>
          {translate("resources.AttributeList.downloadValues")}
          </Button>
        }/>
        <FunctionField render={record =>  <CSVFileInputAttributeList
            id="add-values"
            setLoading={setLoading}
            buttonLabel="resources.AttributeList.updateValues"
            disabled={false}
            mutationFunction={mutationFunction}
          />
        }/>
      </SimpleShowLayout>
      <SimpleShowLayout>
        <ArrayField source='values' >
          <Datagrid bulkActionButtons={false}>
            <TextField source="value" />
            <FunctionField render={record => <UpdateResourceAction resource="AttributeList" data={{action: "delete", attributeValues: [record.value]}} />} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <SimpleShowLayout >
        <FunctionField render={record => {
          return <Form onSubmit={handleSubmit} noValidate id="add-value-to-attribute-list" >
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" width="700px">
              <TextInput source="value" validate={required()} fullWidth />
              <Button sx={{mx: "1em", px: "2em"}} startIcon={<Add />} color="info" variant="outlined" size="small" type="submit">
                {translate("resources.DidList.add_to_list")}
              </Button>
            </Box>    
            </Form> 
        }}
        />
      </SimpleShowLayout>
    </Show>
  );
}


const AttributeListCreate = () => {
  const [attributeValues, setAttributeValues] = useSafeSetState([]);
  const [label, setLabel] = useSafeSetState(null);
  const [pointer, setPointer] = useSafeSetState(null);
  const [credentialType, setCredentialType] = useSafeSetState(null);
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const WatchValues = () => {
    const attributeV = useWatch({ name: 'attributeValues' });
    const watchLabel = useWatch({ name: 'label' });
    const watchPointer = useWatch({ name: 'pointer' });
    const watchCredentialType = useWatch({ name: 'credentialType' });
    label !== watchLabel && setLabel(watchLabel);
    pointer !== watchPointer && setPointer(watchPointer);
    credentialType !== watchCredentialType && setCredentialType(watchCredentialType);
    if (!_.isEqual(attributeV, attributeValues)) {
      setAttributeValues(attributeV);
    }
    return null;
  };

  const transform = values => {
    values.attributeValues = values.attributeValues.map(e => e.attributeValue);
    return values;
  }

  const mutationFunction = async valuesArray => {
    await dataProvider.create("AttributeList", {
      data: { label, pointer, credentialType, attributeValues: valuesArray }
     });
     redirect("/AttributeList");
  }

  if (loading) return <Loading />

  return (
    <Create resource="AttributeList" redirect="show" transform={transform}>
        <SimpleForm warnWhenUnsavedChanges>
          <Container sx={{display: "flex", flexDirection: "column"}}>
            <TextInput source="label" autoComplete="off" validate={required()} />
            <TextInput source="pointer" autoComplete="off" validate={required()} />
            <TextInput source="credentialType" autoComplete="off" />
            <Divider>{translate("resources.AttributeList.uploadFromFile")}</Divider>
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
              <CSVFileInputAttributeList
                id="upload-values"
                setLoading={setLoading}
                buttonLabel="resources.AttributeList.createByValues"
                disabled={!label || !pointer}
                mutationFunction={mutationFunction}
              />
            </Box>
            <Divider>{translate("resources.AttributeList.enterManually")}</Divider>
            <ArrayInput source="attributeValues" validate={required()}>
              <SimpleFormIterator fullWidth disableReordering>
                <TextInput source="attributeValue" />
              </SimpleFormIterator>
            </ArrayInput>
            <WatchValues />
          </Container>
        </SimpleForm>
    </Create>
  );
}



export {AttributeListList, AttributeListShow, AttributeListCreate};
