import { useEffect } from 'react';
import { TextInput, required, useSafeSetState, email } from 'react-admin';
import { useFormContext } from 'react-hook-form';


const EmailLoginInput = ({setOrgAttrs, orgAttrs}) => {
    const { watch, setValue } = useFormContext();
    const emailAddress = watch("emailAddress");
    const [firstRenderDone, setFirstRenderDone] = useSafeSetState(false);

    useEffect(() => {
        setFirstRenderDone(true);
    }, [])

    useEffect(() => {
        if (!emailAddress && firstRenderDone) {
            setOrgAttrs(null);
            setValue("emailAddress", null);
        } else if (emailAddress !== orgAttrs?.emailAddress && firstRenderDone) {
            setOrgAttrs(null);
        }
      }, [emailAddress, firstRenderDone, setOrgAttrs]);

    return <TextInput
        source="emailAddress"
        validate={[required(), email()]}
        fullWidth
        defaultValue={orgAttrs?.emailAddress}
    />
}

export default EmailLoginInput;