import { FunctionField } from 'react-admin';

const WrappedField = ({source, sortable = false}) => <FunctionField
  source={source}
  sortable={sortable}
  render={record => {
    return `${record.id} - ${record.name}`
  }}
/>


export default WrappedField;