import { TranslationMessages } from 'ra-core';

const basqueMessages: TranslationMessages = {
    ra: {
        action: {
            add_filter: 'Gehitu iragazkia',
            add: 'Gehitu',
            back: 'Atzera joan',
            bulk_actions: '1 elementua hautatu |||| %{smart_count} elementu hautatu',
            cancel: 'Utzi',
            clear_array_input: 'Garbitu zerrenda',
            clear_input_value: 'Garbitu balioa',
            clone: 'Klonatu',
            confirm: 'Berretsi',
            create: 'Sortu',
            create_item: 'Sortu %{item}',
            delete: 'Ezabatu',
            edit: 'Editatu',
            export: 'Esportatu',
            list: 'Zerrenda',
            refresh: 'Freskatu',
            remove_filter: 'Kendu iragazki hau',
            remove_all_filters: 'Kendu iragazki guztiak',
            remove: 'Kendu',
            save: 'Gorde',
            search: 'Bilatu',
            select_all: 'Hautatu dena',
            select_row: 'Hautatu lerro hau',
            show: 'Erakutsi',
            sort: 'Ordenatu',
            undo: 'Desegin',
            unselect: 'Desautatu',
            expand: 'Hedatu',
            close: 'Itxi',
            open_menu: 'Ireki menua',
            close_menu: 'Itxi menua',
            update: 'Eguneratu',
            move_up: 'Mugitu gora',
            move_down: 'Mugitu behera',
            open: 'Ireki',
            toggle_theme: 'Txandakatu Gaia',
            select_columns: 'Hautatu zutabeak',
            update_application: 'Eguneratu aplikazioa',
        },
        boolean: {
            true: 'Bai',
            false: 'Ez',
            null: ' ',
        },
        page: {
            create: 'Sortu %{name}',
            dashboard: 'Aginte-panela',
            edit: 'Editatu %{name} %{recordRepresentation}',
            error: 'Akatu bat gertatu da',
            list: '%{name}',
            loading: 'Kargatzen',
            not_found: 'Ez da aurkitu',
            show: 'Erakutsi %{name} %{recordRepresentation}',
            empty: 'Ez dago %{name} oraindik.',
            invite: 'Bat gehitu nahi al duzu?',
        },
        input: {
            file: {
                upload_several: 'Eraman eta askatu fitxategiak kargatzeko, edo sakatu hautatu batentzako.',
                upload_single: 'Eraman eta askatu fitxategi bat kargatzeko, edo sakatu hautatu baterako.',
            },
            image: {
                upload_several: 'Eraman eta askatu irudiak kargatzeko, edo sakatu hautatu batentzako.',
                upload_single: 'Eraman eta askatu irudi bat kargatzeko, edo sakatu hautatu baterako.',
            },
            references: {
                all_missing: 'Ezin izan da erreferentziaren datuak aurkitu.',
                many_missing: 'Erreferentzi batzuk ez dira eskuragarriak.',
                single_missing: 'Erreferentziak ez dira jada eskuragarriak.',
            },
            password: {
                toggle_visible: 'Ezkutatu pasahitza',
                toggle_hidden: 'Erakutsi pasahitza',
            },
        },
        message: {
            about: 'Honi buruz',
            are_you_sure: 'Ziur zaude?',
            auth_error: 'Autentifikazio tokenaren egiaztapenak huts egin du.',
            bulk_delete_content: 'Ziur zaude %{name} ezabatu nahi duzula? |||| Ziur zaude %{smart_count} item hauek ezabatu nahi dituzula?',
            bulk_delete_title: 'Ezabatu %{name} |||| Ezabatu %{smart_count} %{name}',
            bulk_update_content: 'Ziur zaude %{name} eguneratu nahi duzula? |||| Ziur zaude %{smart_count} item hauek eguneratu nahi dituzula?',
            bulk_update_title: 'Eguneratu %{name} |||| Eguneratu %{smart_count} %{name}',
            clear_array_input: 'Ziur zaude zerrenda osoa garbitu nahi duzula?',
            delete_content: 'Ziur zaude item hau ezabatu nahi duzula?',
            delete_title: 'Ezabatu %{name} #%{id}',
            details: 'Xehetasunak',
            error: 'Bezeroaren errore bat gertatu da eta zure eskaera ezin izan da osatu.',
            invalid_form: 'Formularioa ez da baliozkoa. Mesedez, egiaztatu akatsak.',
            loading: 'Itxaron mesedez',
            no: 'Ez',
            not_found: 'URL-a okerra idatzi duzu edo esteka txarra jarraitu duzu.',
            yes: 'Bai',
            unsaved_changes: 'Zure aldaketak ez dira gorde. Ziur zaude hauek baztertu nahi dituzula?',
        },
        navigation: {
            no_results: 'Emaitzarik ez da aurkitu',
            no_more_results: '%{page} orrialde zenbakia mugen atzean dago. Saiatu aurreko orrialdearekin.',
            page_out_of_boundaries: '%{page} orrialde zenbakia mugez kanpo',
            page_out_from_end: 'Azken orrialdetik harago ezin da joan',
            page_out_from_begin: 'Ezin da 1 orrialde baino lehenagora joan',
            page_range_info: '%{offsetBegin}-%{offsetEnd} %{total}-tik',
            partial_page_range_info: '%{offsetBegin}-%{offsetEnd} %{offsetEnd} baino gehiagotik',
            current_page: 'Orrialde %{page}',
            page: 'Joan %{page} orrialdera',
            first: 'Joan lehen orrialdera',
            last: 'Joan azken orrialdera',
            next: 'Joan hurrengo orrialdera',
            previous: 'Joan aurreko orrialdera',
            page_rows_per_page: 'Lerroak orrialdeko:',
            skip_nav: 'Joan edukira',
        },
        sort: {
            sort_by: 'Ordenatu %{field} %{order}-ka',
            ASC: 'igoera',
            DESC: 'jaitsiera',
        },
        auth: {
            auth_check_error: 'Mesedez saioa hasi jarraitzeko',
            user_menu: 'Profil',
            username: 'Erabiltzailea',
            password: 'Pasahitza',
            sign_in: 'Saioa hasi',
            sign_in_error: 'Autentifikazioak huts egin du, saiatu berriro',
            logout: 'Amaitu saioa',
        },
        notification: {
            updated: 'Elementua eguneratu da |||| %{smart_count} elementu eguneratu dira',
            created: 'Elementua sortu da',
            deleted: 'Elementua ezabatu da |||| %{smart_count} elementu ezabatu dira',
            bad_item: 'Elementu okerra',
            item_doesnt_exist: 'Elementua ez da existitzen',
            http_error: 'Zerbitzariarekin komunikazio errorea',
            canceled: 'Ekintza ezeztatu da',
            data_provider_error: 'dataProvider-aren errorea. Ikusi consolearen xehetasunak.',
            i18n_error: 'Ezin izan da zure hizkuntzarako itzulpena aurkitu',
            logged_out: 'Zure sesioa amaitu da, konektatu berriro',
        },
        validation: {
            required: 'Derrigorrezkoa',
            minLength: 'Gutxienez %{min} karaktere behar dira',
            maxLength: '%{max} karaktere edo gutxiago',
            minValue: 'Gutxienez %{min} izan behar da',
            maxValue: '%{max} edo gutxiago izan behar da',
            number: 'Zenbaki bat izan behar da',
            email: 'Baliozko e-posta helbidea izan behar da',
            oneOf: 'Balio bat izan behar da: %{options}',
            regex: 'Urrutiko formatuarekin bat etorri behar da (regexp): %{pattern}',
        },
    },
};

export default basqueMessages;