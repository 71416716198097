import { useEffect } from 'react';
import { Box, Card, Typography, Container, Button, Alert } from '@mui/material';
import {
  AutocompleteInput, useNotify, useDataProvider, ReferenceInput, useSafeSetState,
  required, TextInput, Form, useTranslate, useRedirect, List, BooleanInput,
} from 'react-admin';
import CardTitle from '../components/card_title';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { WerifyLayout } from './layout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {WerifyPointDatagrid} from './dashboard';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import { NoPermissionsLayout } from './no_permissions';
import _ from 'lodash';
import requirePermissions from '../lib/permissions';
import Loading from './loading';


const WerifyPointWizard = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [step, setStep] = useSafeSetState(0);
  const [werifyPoint, setWerifyPoint] = useSafeSetState({});
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  if (loading) return <Loading />;
  if (!requirePermissions.canCreateWerifyPoint(permissions)) { return <NoPermissionsLayout /> };

  return <WerifyLayout>
    <Container maxWidth="md">
      <Breadcrumbs>
        <Button startIcon={<DashboardIcon />} color="primary" variant="outlined" onClick={() => redirect("/")} sx={{mx: 0}} id="go-dashboard">
          {translate("components.menu.dashboard")}
        </Button>
        <Typography variant="body">{ translate("wizard.create_werify_point") }</Typography>
      </Breadcrumbs>
    </Container>
    <Container maxWidth="md" id="werify_point">
      <Box textAlign="center" width="100%" height="auto" margin="2em auto">
          { step === 0 && <CreateWerifyPoint setStep={setStep} setWerifyPoint={setWerifyPoint} /> }
          { step === 1 && <FinishWerifyPoint werifyPoint={werifyPoint} /> }
      </Box>
    </Container>
  </WerifyLayout>
}

const CreateWerifyPoint = ({setStep, setWerifyPoint}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (values) => {
    try {
      let {data: werifyPoint} = await dataProvider.create('WerifyPoint', { data: { input: values }});
      setWerifyPoint(werifyPoint);
      setStep(1);
    } catch (e) {
      notify(e.toString(), { type: 'error' });
    }
  }

  return <Form onSubmit={handleSubmit} noValidate id="name-werify-point-wizard-step">
    <Card>
      <CardTitle text="wizard.name" />
      <Container sx={{my: 4}}>
        <TextInput fullWidth source="name" label="wizard.name" validate={[required()]} helperText="wizard.nameHelperText" />
        <ReferenceInput source="ruleId" reference="Rule" filter={{archivedAtIsSet: false}} >
          <AutocompleteInput
            label="wizard.choose_rule"
            helperText={false}
            optionText={(r) => `${r.id} - ${r.name}` }
            validate={[required()]}
          />
        </ReferenceInput>
        <BooleanInput sx={{mt: 1, ml: 2}} source="timer" label="wizard.timer" helperText="wizard.timerHelperText" />
      </Container>
    </Card>

    <ButtonBar>
      <Box flexGrow="1"></Box>
      <Button endIcon={<ArrowForwardIcon />} color="inverted" variant="contained" type="submit" id="create-point">
        {translate("wizard.finish")}
      </Button>
    </ButtonBar>
  </Form>
}

const FinishWerifyPoint = ({werifyPoint}) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  return <Box>
  <Alert severity="success" sx={{my: "2em" }}>
  { translate("wizard.werify_point_created_text") }
  </Alert>
  <Card>
    <CardTitle text="wizard.werify_point_created_title" />
      <Container>
        <List
          empty={<Box sx={{m: 1}}>{ translate("wizard.no_werify_points") }</Box>}
          resource="WerifyPoint"
          pagination={false}
          filter={{ idEq: werifyPoint.id, archivedAtIsSet: false }}
          actions={false}
        >
          <WerifyPointDatagrid />
        </List>
    </Container>
  </Card>
  <ButtonBar justifyContent="flex-end">
    <Button endIcon={<ArrowForwardIcon />} color="primary" variant="outlined" onClick={() => redirect("/")} id="go-dashboard">
      {translate("wizard.go_dashboard")}
    </Button>
  </ButtonBar>
</Box>
}

export const ButtonBar = ({children, justifyContent}: {children: any, justifyContent?: string}) => 
  <Box display="flex" flexWrap="wrap-reverse" gap="1em" marginTop="2em" justifyContent={justifyContent}>
    {children}
  </Box>

  

export default WerifyPointWizard;
