import { Box } from '@mui/material';
import CredentialCard from './credential_card';
import { useTranslate } from 'react-admin';
import _ from 'lodash';
import { parseDate } from './utils';


const CredentialsContainer = ({ attempt, onlyErrors }) => {
  const translate = useTranslate();
  const parsedData = attempt?.storedValues ? JSON.parse(attempt.storedValues) : [];
  const parsedErrors = attempt?.errors ? JSON.parse(attempt.errors) : [];

  const constructError = (filterType, value) => {
    const translatedFilterType = translate(`resources.KnownAttribute.fields.filters.${filterType}`);
    if (filterType === "Exists") {
      return translate('resources.Attempt.newErrorExists');
    }

    if (filterType === "ConditionCompliance" || value === ".") {
      if (attempt.stateNotes?.split(" ")[1]) {
        return attempt.stateNotes;
      }
      if (value === "single_use_credentials") {
        return translate('resources.Attempt.fields.stateNotesValues.single_use_credentials');
      }

      return translate(`resources.Attempt.fields.stateNotesValues.${attempt.stateNotes}`);
    }

    if (filterType === "Revoked") {
      let status = !isNaN(Date.parse(value)) ? "revokedAt" : value;
      return translate(`resources.Attempt.status.${status}`, {at: parseDate(value)})
    }
    
    return translate('resources.Attempt.newError', {filter: translatedFilterType, value});
  }

  const credentials = onlyErrors ?
    processSelectedCredentialsAndErrors(parsedData, parsedErrors, constructError)
    :
    processCredentialsAndErrors(parsedData, parsedErrors, constructError);
  
  return (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" flexWrap="wrap" margin="1em">
        {credentials.map((credential, index) => (
          <CredentialCard key={index} credential={credential} />
        ))}
      </Box>
  );
};

const processSelectedCredentialsAndErrors = (data, errors, constructError) => {
  const processedData = {};

  _.filter(data, i => i.pointer === "/type").forEach(item => {
    const credentialIndex = item.index[0];
    if (_.filter(errors, error => error.index[0] === item.index[0]).length > 0) {
      if (!processedData[credentialIndex]) {
        processedData[credentialIndex] = {};
      }
      processedData[credentialIndex]["type"] = {
        value: formatValue("type", item.value),
        error: null,
        filter: null,
        inline: true
      };
    }
  })
  
  errors.forEach(error => {
    const errorIndex = error.index[0];
    const pointer = error.pointer.startsWith('/') ? error.pointer.substring(1) : error.pointer;

    // Si hay un error, asegúrate de guardar la información
    if (!processedData[errorIndex]) {
      processedData[errorIndex] = {};
    }
    if (!processedData[errorIndex][pointer]) {
      processedData[errorIndex][pointer] = { value: null, error: null, filter: null, inline: true };
    }

    const filterType = Object.keys(error.condition.filter)[0];
    const value = error.condition.filter[filterType];
    processedData[errorIndex][pointer].filter = filterType;
    processedData[errorIndex][pointer].error = constructError(filterType, value);
  });

  return Object.values(processedData);
};

const processCredentialsAndErrors = (data, errors, constructError) => {
  const processedData = {};

  data.forEach(item => {
    const credentialIndex = item.index ? item.index[0] : 0;
    const pointer = item.pointer.startsWith('/') ? item.pointer.substring(1) : item.pointer;
    if (!processedData[credentialIndex]) {
      processedData[credentialIndex] = {};
    }
  
    processedData[credentialIndex][pointer] = {
      value: formatValue(pointer, item.value),
      error: null,
      filter: null,
      inline: false,
    };
  });

  errors.forEach(error => {
    const errorIndex = error.index ? error.index[0] : 0;
    const pointer = error.pointer.startsWith('/') ? error.pointer.substring(1) : error.pointer;

    if (!processedData[errorIndex]) {
      processedData[errorIndex] = {};
    }
    if (!processedData[errorIndex][pointer]) {
      processedData[errorIndex][pointer] = { value: null, error: null, filter: null, inline: false };
    }

    const filterType = Object.keys(error.condition.filter)[0];
    const value = error.condition.filter[filterType];
    processedData[errorIndex][pointer].filter = filterType;
    processedData[errorIndex][pointer].inline = filterType === "Exists";
    processedData[errorIndex][pointer].error = constructError(filterType, value);
  });

  return Object.values(processedData);
};

const formatValue = (key, value) => {
  if (key === 'type' && _.isArray(value)) {
    return value[value.length - 1];
  }
  return _.isObject(value) ? JSON.stringify(value) : value;
};


export default CredentialsContainer;