import { useEffect } from 'react';
import {
    Typography, Container, Button, Box
} from '@mui/material';
import {
  useDataProvider, useSafeSetState, useTranslate, useRedirect, useGetOne
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { WerifyLayout } from '../layout';
import {DesignServices} from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import _ from "lodash";
import { RuleEditor } from './general_editor';
import { NoPermissionsLayout } from '../no_permissions';
import authProvider, { getPermissionsAndSetThem } from '../../lib/auth_provider';
import requirePermissions from '../../lib/permissions';
import Loading from '../loading';


const RuleWizardEdit = () => {
  const { id } = useParams();
  const translate = useTranslate();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);
  const {isLoading, data: rule} = useGetOne('Rule', { id });

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  const handleSubmit = async (values, directives, setError) => {
    setError(null);
    try {
      await dataProvider.update('Rule', { data: { input: {
        id: parseInt(id),
        name: values.name,
        redirectUrl: values.redirectUrl,
        singleUseCredentials: values.singleUseCredentials,
        directives: JSON.stringify(directives)
      } } });
      redirect(`/Rule/${id}/show`);
    } catch(e) {
      setError(e.body.message);
    }
  }

  if (loading || isLoading) return <Loading />;
  if (!requirePermissions.canCreateRule(permissions)) { return <NoPermissionsLayout /> };

  return <WerifyLayout>
    <Container maxWidth="md">
      <Breadcrumbs>
        <Button startIcon={<DesignServices />} color="primary" variant="outlined" onClick={() => redirect(`/Rule/${id}/show`)} sx={{mx: 0}} id="show-rule">
          {translate("resources.Rule.name", {smart_count: 1})}
        </Button>
        <Typography variant="body">{ translate("wizard.edit_rule") }</Typography>
      </Breadcrumbs>
    </Container>
    <Container maxWidth="md" id="rule">
      <Box textAlign="center" width="100%" height="auto" margin="2em auto">
        <RuleEditor handleSubmit={handleSubmit} action="update" rule={rule} />
      </Box>
    </Container>
  </WerifyLayout>
}

export default RuleWizardEdit;