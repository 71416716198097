import { Confirm, useDataProvider, useSafeSetState, useNotify, useGetRecordId, useRefresh, useTranslate, useRedirect } from 'react-admin';
import { IconButton, Button } from '@mui/material';
import { StarBorder, Star } from '@mui/icons-material';

const FavWerifyPoint = ({action, admin = false}) => {
  const resourceId = useGetRecordId();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const icon = action === "set_favorite" ? <StarBorder /> : <Star />;

  const handleFav = async () => {
    try {
      await dataProvider.update("WerifyPoint", {
        id: resourceId, data: {input: {id: resourceId, action}}, previousData: {} 
      });
    } catch (e) {
      notify(`vc_validator.fav_werify_point.${action}.errorUpdating`, { type: 'error' });
    }
    refresh();
  }

  return <>
    {admin ?
    <Button color="warning" size="small" onClick={() => handleFav()} startIcon={icon} id={`${action}-${resourceId}`}>
      {translate(`vc_validator.fav_werify_point.${action}.name`)}
    </Button>
    :
    <IconButton color="warning" size="medium" onClick={() => handleFav() } id={`${action}-${resourceId}`}>
      {icon}
    </IconButton>
    }
  </>
}


export {FavWerifyPoint};
