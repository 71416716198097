
import { ContactMail } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslate } from 'react-admin';

const LoginButton = ({sx, id, onClick, type, disabled} : {sx?: any, id: String, onClick?: Function, type?: String, disabled?: boolean}) => {
    const translate = useTranslate();
    return <Button
        sx={sx}
        id={id}
        variant="contained"
        size="large"
        fullWidth
        startIcon={<ContactMail />}
        onClick={onClick}
        disabled={disabled}
        type={type}
  >
    { translate("components.login.login_button") }
  </Button>
}

export default LoginButton