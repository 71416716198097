import { useEffect, useRef } from 'react';
import {
    Divider, Paper, Dialog, DialogTitle, TextField, Typography,
    Button, Box, IconButton, Switch, FormControlLabel, List, ListItem, ListItemText, Pagination,
    ListItemButton, ListItemIcon, Select, InputLabel, FormControl, MenuItem, TextField as MuiTextField
} from '@mui/material';
import { useSafeSetState, useTranslate, useGetList } from 'react-admin';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WerifyAutocomplete from '../../components/autocomplete';
import _ from "lodash";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { muiLocale } from '../../i18n';
import {extensiveFilterKinds} from '../../lib/types';
import Loading from '../loading';
import 'dayjs/locale/es';

dayjs.locale('es'); 


const directivesToBasicEditorState = (directives) => {
  if (_.isEmpty(directives)) {
    return {[ _.uniqueId("cond_")]: {did: null, credentialType: null, conditions: {} }};
  }
  let result = {};

  for (let credentialSpec of directives.acceptable_sets[0].required_set) {
    const directives = credentialSpec.credential_spec;

    const issuerFilter = _.find(directives, (d) => d.pointer == "/issuer")?.filter;
    const did = issuerFilter?.DidIs || (issuerFilter?.InDidList && "any-known-did");

    const credentialType = _.find(directives, (d) => d.pointer == "/type")?.filter?.ArrayContains;
    const rest = _.reject(directives, (d) =>
      (d.pointer == "/issuer" && d.filter.DidIs) || (d.pointer == "/type" && d.filter.ArrayContains)
    );
    const conditions = _.fromPairs(_.map(rest, (d) => {
      const entries = Object.entries(d?.filter)[0];
      return [ _.uniqueId("cond_"), { pointer: d.pointer, filter: entries[0], value: entries[1], store: d.store } ];
    }));

    result[_.uniqueId("spec_")] = { did, credentialType, conditions };
  }

  return result;
}

const basicEditorStateToDirectives = (state) => {
  let required_set = [];

  for(let spec of _.values(state)) {
    let conditions = [];
    if(spec.did == "any-known-did") {
      conditions.push({ pointer:"/issuer", filter: {"InDidList": "any" } })
    } else if (_.startsWith(spec.did, "list-")) {
      conditions.push({ pointer:"/issuer", filter: {"InDidList": _.trim(spec.did) } })
    } else if (spec.did) {
      conditions.push({ pointer:"/issuer", filter: {"DidIs": _.trim(spec.did)} })
    }
    if(spec.credentialType) {
      conditions.push({ pointer:"/type", filter: {"ArrayContains": _.trim(spec.credentialType)} })
    }
    for(let cond of _.values(spec.conditions)) {
      const filter = _.trim(cond.filter);
      const value = _.trim(cond.value);
      if (filter === "InDidList" && (spec.did === "any-known-did" || _.startsWith(value, "list-")) || filter !== "InDidList") {
        conditions.push({ pointer: _.trim(cond.pointer), filter: { [filter]: value }, store: cond.store});
      }
    }
    required_set.push({ "credential_spec" : conditions });
  }

  return { "acceptable_sets": [ { "required_set": required_set } ] };
}

const validateEditorState = (state) => {
  let valid = true;

  if( _.isEmpty(state) ) {
    valid = false;
  }

  for(let spec of _.values(state)) {
    spec.credentialTypeError = null;
    spec.didError = null

    if(_.isEmpty(spec.credentialType) && _.isEmpty(spec.did) && _.isEmpty(spec.conditions) ){
      valid = false;
      continue;
    }

    if(spec.credentialType?.length < 5) {
      spec.credentialTypeError = "credential_type_length";
      valid = false;
    }

    if(spec.did?.length < 5) {
      spec.didError = "did_length";
      valid = false;
    }

    for(let cond of _.values(spec.conditions)) {
      cond.error = null;

      if(_.isEmpty(_.trim(cond.pointer))) {
        cond.error = "pointer_cannot_blank";
        valid = false;
        continue;
      }

      if (/[^A-Za-z0-9 /_-]/.test(_.trim(cond.pointer))) {
        cond.error = "invalid_pointer";
        valid = false;
        continue;
      }

      const filter = _.trim(cond.filter);
      if(_.isEmpty(filter)) {
        cond.error = "filter_cannot_blank";
        valid = false;
        continue;
      }

      if( filter == "Exists" || filter == "InDidList" ) {
        cond.value = "any";
      } else {
        if(_.isEmpty(_.trim(cond.value))) {
          cond.error = "value_cannot_blank";
          valid = false;
          continue;
        }
      }
    }
  }

  return [valid, {...state}];
}

const Basic = ({currentEditor, directives, updateDirectives, setError}) => {
  const { data: knownDids, isLoading: didsLoading } = useGetList('KnownDid');
  const { data: knownCredentialTypes, isLoading: credsLoading } = useGetList('KnownCredentialType');
  const { data: didList, isLoading: didListLoading } = useGetList('DidList');
  const [state, setState] = useSafeSetState(directivesToBasicEditorState(directives));
  const translate = useTranslate();

  useEffect(() => {
    if(currentEditor != "basic") {
      setState(directivesToBasicEditorState(directives))
    }
  }, [directives]);
  
  if (didsLoading || credsLoading || didListLoading ) { return <Loading /> };
  
  const validateAndSetState = (newState) => {
    const [valid, validated] = validateEditorState(newState);
    
    if(valid) {
      updateDirectives(basicEditorStateToDirectives(validated), "basic");
      setError(null);
    } else {
      updateDirectives(null, "basic");
      setError("basic_editor_error");
    }

    setState(validated);
  }

  const updateState = (specEditorId, specEditorState) => {
    validateAndSetState({...state, [specEditorId]: specEditorState});
  }

  const addSpecEditor = () => {
    validateAndSetState({...state, [_.uniqueId("spec_")]: { did: null, credentialType: null, conditions: {} } });
  }

  const removeSpecEditor = (key) => {
    validateAndSetState(_.omit(state, key));
  }

  return <Box>
    { Object.entries(state).map(([key, specEditorState]) => 
      <Box className="spec-editor" key={key} px="0.5em" mt="1em" mb="2em">
        <Divider />
        <Box display="flex" alignItems="center">
          <Typography sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "left",
            flexGrow: 2,
          }}>
            { translate("wizard.create_rules.credential_title") }
          </Typography>
          <IconButton onClick={() => removeSpecEditor(key) } color="error" >
            <RemoveCircleIcon/>
          </IconButton>
        </Box>
        <SpecEditor
          id={key}
          updateBasicEditorState={updateState}
          initialState={specEditorState}
          knownDids={knownDids}
          knownCredentialTypes={knownCredentialTypes}
          didList={didList}
        />
      </Box>
    )}
    <Button startIcon={<AddCircleIcon/>} variant="contained" fullWidth onClick={addSpecEditor} id="add-extra-credential">
      {translate("wizard.create_rules.add_extra_credential")}
    </Button>
  </Box>
}

export default Basic;
  
const SpecEditor = ({ id, updateBasicEditorState, initialState, knownDids, knownCredentialTypes, didList }) => {
  const [ state, setState ] = useSafeSetState(initialState);
  const translate = useTranslate();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    updateBasicEditorState(id, state);
  }, [state]);

  const onChangeDid = (resource) => {
    setState((v) => ({...v, did: resource}) )
  }

  const onChangeCredentialType = (resource) => {
    setState((v) => ({...v, credentialType: resource}) )
  }

  const onChangeCondition = (id, resource) => {
    setState( (v) => {
        v.conditions[id] = resource;
        return {...v};
    })
  }

  const addFieldCondition = (state) => {
    setState( (v) => {
      v.conditions[_.uniqueId("cond_")] = state;
      return {...v};
    });
  }
  
  const deleteFieldCondition = (key) => {
    setState( (v) => {
      delete v.conditions[key]
      return {...v};
    })
  }

  const didChoices = [
    { value: "any-known-did", label: translate("wizard.create_rules.any_did") },
    ...didList.map(l => ({value: `list-${l.id}`, label: l.name})),
    ...knownDids.map((k) => ({ value: k.did, label: k.label }))
  ];

  return <Box>
    <WerifyAutocomplete
      id={ `${id}-known-dids` } 
      className="issuer"
      initialValue={state.did}
      choices={didChoices}
      label="wizard.create_rules.did_label"
      helperText="wizard.create_rules.did_helper"
      errorText={ state.didError && `basic_rules_error.${state.didError}` }
      onChange={ onChangeDid }
    />
    <WerifyAutocomplete
      id={ `${id}-known-credential-types` } 
      className="credential-type"
      initialValue={state.credentialType}
      choices={knownCredentialTypes.map((k) => ({ value: k.value, label: k.value }))}
      label="wizard.create_rules.type_label"
      helperText="wizard.create_rules.type_helper"
      errorText={ state.credentialTypeError && `basic_rules_error.${state.credentialTypeError}` }
      onChange={ onChangeCredentialType }
    />
    <Box sx={{ my: "0.5em" }}>
      <Typography textAlign="left" textTransform="uppercase" fontSize="0.8em">
        {translate("wizard.create_rules.conditions_title")}
      </Typography>
      { Object.entries(state.conditions).map(([key, cond]) => 
        <Paper className="cond-editor" variant="outlined" key={ key } sx={{ mt:"1em", borderColor: cond.error ? "error.main": "secondary.light"}} >
          <Box sx={{ padding: "0.5em", display:"flex", gap:"0.5em", alignItems:"flex-start"}}>
            <ConditionEditor
              id={key}
              initialState={cond}
              updateSpecEditorState={(val) => onChangeCondition(key, val) }
            />
            <IconButton onClick={ () => deleteFieldCondition(key)} className="delete-button" id={`${key}_delete-button`} >
              <RemoveCircleIcon/>
            </IconButton>
          </Box>
          { cond.error &&
            <Box fontSize="0.8em" lineHeight="0.8em" textAlign="left" padding="1em" color="error.main" className="basic-errors">
              { translate(`basic_rules_error.${cond.error}`) }
            </Box>
          }
        </Paper>
      )}
      <AddConditionButton addCondition={addFieldCondition} credentialType={state.credentialType} />
    </Box>
  </Box>
}
  
const ConditionEditor = ({id, updateSpecEditorState, initialState}) => {
  const [state, setState] = useSafeSetState(initialState);
  const translate = useTranslate();

  useEffect(() => {
    updateSpecEditorState(state);
  }, [state]);

  const updateState = (update) => setState((s) => ({...s, ...update}) );
  const setValue = (e) => updateState({value: e.target.value});
  const setValueFromDate = (e) => {
    try {
      updateState({value: e.toISOString()});
    } catch {
      updateState({value: "" });
    }
  }
  const setPointer = (e) => updateState({pointer: e.target.value});
  const setFilter = (e) => updateState({filter: e.target.value, value: null});
  const setStore = (e) => updateState({store: e.target.checked});

  let valueInput;
  switch (state.filter) {
    case "DateAfter":
    case "DateBefore":
      valueInput = "Date";
      break;
    case "NumberGreaterThan":
    case "NumberLesserThan":
    case "OlderThanYears":
    case "YoungerThanYears":
      valueInput = "Number";
      break;
    case "Exists":
    case "InDidList":
      valueInput = null;
      break;
    default: valueInput = "Text";
  }

  return <Box className="cond-editor-fields" display="flex" flexWrap="wrap" flexGrow="10" alignItems="center" gap="1em" textAlign="left" fontSize="0.4em">
      <TextField
        value={state.pointer || ""}
        sx={{flexGrow: 1, minWidth: "20vw"}}
        id={ `${id}-pointer` } 
        className="pointer"
        label={translate("wizard.create_rules.pointer_label")}
        onChange={setPointer}
        size="small"
        variant="outlined"
        helperText={false}
        disabled={state.filter === "InAttributeList"}
      />

      <FormControl 
        variant="outlined"
        sx={{flexGrow: 1}}
      >
      <InputLabel>{translate("wizard.create_rules.filter_label")}</InputLabel>
      <Select
        className="filter"
        id={`${id}-filter`}
        value={state.filter || ""}
        label={translate("wizard.create_rules.filter_label")}
        onChange={setFilter}
      >
        { extensiveFilterKinds.map((v) => <MenuItem key={v} value={v}>
          { translate(`resources.KnownAttribute.fields.filters.${v}`) }
        </MenuItem> ) 
        }
      </Select>
      </FormControl>
      { valueInput == "Date" &&
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={ muiLocale }>
          <DateField
            size="small"
            variant="outlined"
            value={dayjs(state.value || "")}
            className="value-date"
            id={ `${id}-value-date` } 
            label={translate("wizard.create_rules.value_label")}
            helperText={false}
            onChange={setValueFromDate}
            slotProps={{field: { variant: "outlined"}}}
            sx={{flexGrow: 1}}
          />
        </LocalizationProvider>
      }
      { valueInput == "Text" &&
        <TextField
          size="small"
          variant="outlined"
          className="value-text"
          value={state.value || ""}
          id={ `${id}-value-text` } 
          label={translate("wizard.create_rules.value_label")}
          helperText={false}
          onChange={setValue}
          sx={{flexGrow: 1}}
          disabled={state.filter === "InAttributeList"}
        />
      }
      { valueInput == "Number" &&
        <TextField
          type="number"
          InputLabelProps={{
          shrink: true,
          }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          size="small"
          variant="outlined"
          value={state.value || ""}
          className="value-number"
          id={ `${id}-value-number` } 
          label={translate("wizard.create_rules.value_label")}
          helperText={false}
          onChange={setValue}
          sx={{flexGrow: 1}}
        />
      }
      <FormControlLabel
        sx={{ ml: "1em", flexGrow: 1}} 
        className="store"
        control={<Switch value={!!state.store} checked={!!state.store} />}
        label={translate("wizard.create_rules.store_label")}
        onChange={setStore}
      />
  </Box>
}
  
const AddConditionButton = ({ addCondition, credentialType }) => {
  const [open, setOpen] = useSafeSetState(false);
  const [credentialTypeLike, setCredentialTypeLike] = useSafeSetState(credentialType);
  const [prevCredentialType, setPrevCredentialType] = useSafeSetState(credentialType);
  const [count, setCount] = useSafeSetState(0);
  const [page, setPage] = useSafeSetState(1);
  const perPage = 15;

  const translate = useTranslate();
  const {data: knownAttributes, total: totalAttributes, isLoading: isLoadingAttributes} = useGetList(
    'KnownAttribute',
    { filter: { credentialTypeLike }, pagination: { page, perPage }, sort: { field: "label", order: "ASC" } }
  );
  const {data: attributeLists, total: totalLists, isLoading: isLoadingList} = useGetList(
    'AttributeList',
    { filter: { credentialTypeLike }, pagination: { page, perPage }, sort: { field: "label", order: "ASC" } }
  );

  useEffect(() => {
    if (credentialType !== prevCredentialType) {
      setCredentialTypeLike(credentialType);
      setPrevCredentialType(credentialType);
    }
    if (!isLoadingAttributes && !isLoadingList) {
      setCount(_.ceil((totalAttributes + totalLists) / perPage));
    }
  }, [credentialType, prevCredentialType, totalAttributes, totalLists]);
  
  const handleListItemClick = (selection, resource) => {
    const isList = resource === "AttributeList";
    const filter = isList ? "InAttributeList" : selection.filter || null;
    const value = isList ? `list-${selection.id}` : null;
    addCondition({
      pointer: selection.pointer || null,
      filter,
      value,
      store: true,
    })
    setOpen(false);
  };

  return <Box>
    <Button sx={{ my: "1em" }} size="small" color="inverted" startIcon={<AddCircleIcon/>} variant="contained" fullWidth onClick={() => setOpen(true)} id="add-condition-button">
    {translate("wizard.create_rules.add_condition")}
    </Button>
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle> {translate("wizard.create_rules.add_condition")} </DialogTitle>
      <MuiTextField 
        sx={{mx: "1em", mb: "1em"}}
        id="filter-credential-type"
        autoComplete="off"
        label={translate("resources.KnownAttribute.fields.credentialTypeLike")}
        value={credentialTypeLike}
        onChange={(e) => {
          const value = e.target.value;
          setCredentialTypeLike(value !== "" ? value : null);
        }}
      />
      <List sx={{ pt: 0, overflow: "visible" }} id="known-condition-list">
        <ListItem dense disableGutters>
        <ListItemButton onClick={() => handleListItemClick({}, "")} key="custom">
          <ListItemIcon>
          <AddCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={translate("wizard.create_rules.add_condition_custom_primary")}
            secondary={translate("wizard.create_rules.add_condition_custom_secondary")}
          />
        </ListItemButton>
        </ListItem>
        {attributeLists?.map(attrList => (
          <ListItem key={attrList.id} dense disableGutters>
            <ListItemButton onClick={() => handleListItemClick(attrList, "AttributeList")} key={attrList.id}>
            <ListItemIcon>
              <RadioButtonUncheckedIcon />
            </ListItemIcon>
            <ListItemText primary={attrList.label} secondary={ `${attrList.pointer} - ${translate(`resources.KnownAttribute.fields.filters.InAttributeList`)}` } />
            </ListItemButton>
          </ListItem>
        ))}
        {knownAttributes?.map(knownAttr => (
          <ListItem key={knownAttr.id} dense disableGutters>
            <ListItemButton onClick={() => handleListItemClick(knownAttr, "KnownAttribute")} key={knownAttr.id}>
            <ListItemIcon>
              <RadioButtonUncheckedIcon />
            </ListItemIcon>
            <ListItemText primary={knownAttr.label} secondary={ `${knownAttr.pointer} - ${translate(`resources.KnownAttribute.fields.filters.${knownAttr.filter}`)}` } />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center" sx={{mb: 2}} id="add-condition-pagination">
        <Pagination count={count} page={page} onChange={(e, v) => setPage(v)} />
      </Box>
    </Dialog>
  </Box>;
}
