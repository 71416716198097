import { useEffect } from 'react';
import { Menu, useSafeSetState, useResourceDefinitions, useTranslate } from 'react-admin'
import { Box, IconButton, Backdrop, Button, CircularProgress } from '@mui/material';
import {PermIdentity} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import { Head2 } from '../theme';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import _ from 'lodash';
import { NoPermissions } from './no_permissions';
import requirePermissions from '../lib/permissions';
import Loading from './loading';
export const PersonIcon = PermIdentity;


export const ResourceLayoutWrite = ({children}) => {
  const resources = _.omit(useResourceDefinitions(), ["AccountState"]);
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  if (loading)return <Loading />;
  if (!requirePermissions.canWriteAdminResources(permissions)) { return <NoPermissions /> };

  return <Box>
    <DesktopAdminBar children={children} resources={resources} />
    <ResponsiveAdminBar children={children} resources={resources} />
  </Box>
}

const ResourceLayout = ({children}) => {
  const resources = _.omit(useResourceDefinitions(), ["AccountState"]);
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);

  useEffect(() => {
    getPermissionsAndSetThem({authProvider, setPermissions, setLoading});
  }, []);

  if (loading) return <Loading />;
  if (!requirePermissions.canReadAdminResources(permissions)) { return <NoPermissions /> };

  return <Box>
    <DesktopAdminBar children={children} resources={resources} />
    <ResponsiveAdminBar children={children} resources={resources} />
  </Box>
}

const DesktopAdminBar = ({children, resources}) => {
  return <Box sx={{display: {xs:"none", md: "flex"}}} flexDirection="row" marginX={2} gap="2em" id="desktop-admin-menu">
  <Box flexGrow={2} minWidth="max(10vw, 230px)" >
    <Menu>
      {Object.keys(resources).map(name => (
        <Menu.ResourceItem key={name} name={name} />
      ))}
    </Menu>
  </Box>
  <Box flexGrow={9}>
    <Box>
    {children}
    </Box>
  </Box>
</Box>
}

const ResponsiveAdminBar = ({children, resources}) => {
  const [menuOpen, setMenuOpen] = useSafeSetState(false);
  const translate = useTranslate();
  return <Box sx={{display: {xs:"flex", md: "none"}}} flexDirection="column" marginX={1} gap="2em" id="mobile-admin-menu">
    <Box>
      <Button
        size="large"
        onClick={() => setMenuOpen(true) }
        fullWidth
        startIcon={<ListIcon />}
      >
        <Head2>{ translate("components.menu.resources") }</Head2>
      </Button>
      <Backdrop
        sx={{ color: '#fff', backgroundColor:"rgba(0, 0, 0, 0.9)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={menuOpen}
        onClick={() => setMenuOpen(false) }
      >
        <Box display="flex" flexDirection="column" sx={{ '& .MuiMenuItem-gutters': { color: "#aaa" } , '& .RaMenuItemLink-icon': { color: "#fff" }, '& .RaMenuItemLink-active': { color: "#555" }}}>
          <IconButton sx={{ "svg": { fontSize: "80px !important" }, mb: 2 }} color="inverted" onClick={ () => setMenuOpen(false) } >
            <CloseIcon />
          </IconButton>
          {Object.keys(resources).map(name => (
            <Menu.ResourceItem key={name} name={name} />  
          ))}
        </Box>
      </Backdrop>
    </Box>
    <Box flexGrow={9}>
      <Box>
      {children}
      </Box>
    </Box>
  </Box>
}

  

export default ResourceLayout;
