import {
  List, Datagrid, ShowButton, TextField, Show, SimpleShowLayout,
  ReferenceField, TopToolbar, DateField, useGetOne, FunctionField
} from 'react-admin';
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import SelectAttemptState from '../components/select_attempt_state';
import TranslatedTextField from '../components/translated_textfield';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import CredentialsContainer from '../components/credentials_container';
import FilterTextInput from '../components/filter_textinput';
import WrappedField from '../components/wrapped_field';
export const PersonIcon = PermIdentity;


function AttemptList() {

  const attemptFilters = [
    <SelectAttemptState source="stateEq" alwaysOn={true} />,
    <FilterTextInput source="idEq" alwaysOn />,
    <FilterTextInput source="werifyPointIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={attemptFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar />}
    >
      {attemptGrid}
    </List>
  );
}

const attemptGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source="id" />
    <ReferenceField source="werifyPointId" reference="WerifyPoint" link="show">
      <WrappedField source="name" />
    </ReferenceField>
    <ReferenceField source="ruleId" reference="Rule" link="show" sortable={false}>
      <WrappedField source="name" />
    </ReferenceField>
    <TranslatedTextField source="state" translation="resources.Attempt.fields.states" />
    <DateField source="finishedAt" showTime={true} showDate={true}/> 
    <ShowButton />
  </Datagrid>


function AttemptShow(){
  const { id } = useParams();
  const { data: attempt, isLoading } = useGetOne("Attempt", { id });

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="werifyPointId" reference="WerifyPoint" link="show">
          <WrappedField source="name" />
        </ReferenceField>
        <ReferenceField source="ruleId" reference="Rule" link="show">
          <WrappedField source="name" />
        </ReferenceField>
        <FunctionField source="state" render={record => {
          const errorCase = record.state === "REJECTED" ? {color: "red", fontWeight: "600"} : null;
          return <TranslatedTextField source="state" color={errorCase?.color} fontWeight={errorCase?.fontWeight} translation="resources.Attempt.fields.states" />
        }}/>
        <FunctionField source="stateNotes" render={record => {
          return attempt.stateNotes?.split(" ")[1] ?
            <TextField source="stateNotes" />
            :
            <TranslatedTextField source="stateNotes" translation="resources.Attempt.fields.stateNotesValues" />
        }}/>
        <DateField source="finishedAt" showTime={true} showDate={true} />
        <TextField source="did" />
      </SimpleShowLayout>
      {!isLoading && attempt.storedValues && <CredentialsContainer attempt={attempt} onlyErrors={false} /> }
    </Show>
  );
}


export {AttemptList, AttemptShow};
